.custom-menu{
    .menu-item{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        cursor: pointer;
        border-left: transparent 4px solid ;

        .badge-arrow-container{
          .arrow-icon{
            font-size: 20px;
            color: #006837;
          }

          .badge {
            img{ height: 35px;}
          }
        }
        

        &.selected, &:hover{
            background-color: rgba(194, 234, 189, 0.2);
            border-left-color: #ff3b46;

            .circle{
                background-color: #ff3b46;
                color: white;
              }
          
          
              .name-title-container{
                .name{
                  color: #ff3b46;
                }
          
                .title{
                  color: #006837;
                }
              }
        }

        
    }
}