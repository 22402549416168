.view-qr-code{
    .icon{width: 50px; cursor: pointer; transition: all 0.4s; display: block;
        &:hover{opacity: .8;}
        img{width: 100%;}
    }
    display: flex;
    justify-content:end;
    @media only screen and (max-width: 992px) {padding-right: 20px;}
    @media only screen and (max-width: 790px) {justify-content:center; }
    @media only screen and (max-width: 540px) {justify-content:center; padding-top: 20px; padding-bottom: 20px; }

}
.view-qr-modal{ width: 350px !important;
    .ant-modal-body{display: flex; justify-content: center; align-items: center; padding: 30px 0;
        img{width: 300px;}
    }
}