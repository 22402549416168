.travel-request{
    .expenseDeleteCol {
        width: 100%;
        display: flex;
        align-items: end;
    }
    .travelType{
        margin-bottom: 20px;
    }   
    .ant-descriptions-item-label{
        font-weight: bold;
    }
    // .ant-form-item-label{
    //     font-weight: bold;
    // }

    .ant-form-item-control-input-content{
        padding-right: 30px;
        .ant-picker{
            width: 100%;           
        }
    }

    .ant-input-group-addon{
        display: none;
    }
    #horizontal_login_delegatedUser{
        padding-left: 5px;
    }
    .ant-input-affix-wrapper{
        border-radius: 50px !important;
    }
}