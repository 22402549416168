.mentorship-process-detail {
  padding: 16px;
  background-color: #ffffff;
  border-radius: 20px;

  .header {
    .first-row {
       display: flex;
       flex-direction: row;
       justify-content: space-between;

       @include MQ(SM){
         flex-direction: column;
       }

      .title-col {
        .title {
          font-size: 25px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: normal;
          text-align: left;
          color: #23374d;
        }
        .subtitle {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.13;
          letter-spacing: normal;
          text-align: left;
          color: #96a8b7;
        }
      }
      .action-buttons {
          display: flex;
          flex-direction: row;

          @include MQ(SM){
            flex-direction: column;
          }
        
          
          button{
              margin-left: 10px;
              &:first-child{
                margin-left: 0px;
              }

              @include MQ(SM){
                margin-left: 0px;
                margin-top: 10px;

                &:first-child{
                  margin-top: 0px;
                }
              }
          }
      }
    }
    .second-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;


      @include MQ(SM){
        flex-direction: column;
      }
    }
  }
}
