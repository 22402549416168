.birthdays-tab {

    .ant-tabs {
        overflow: inherit !important;
    }

    .ant-tabs-tabpane {
        overflow : auto;
    }

    .ant-tabs-nav-list {
        flex: 1;
        justify-content: space-around;

        .ant-tabs-ink-bar {
            background: #10a3e9 !important;
        }

        .ant-tabs-tab {
            padding: 12px 15px;
        }

        .ant-tabs-tab-btn {
            font-size: 16px;
            font-weight: bold;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #fff;
            background: var(--primary-impact-color);
            flex-direction: column;
        }
    }

    .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
        width: 30px;
        height: 40px;
        background: #fff;
        border: 0px;
        border-radius: 12px !important;
        justify-content: center;
        display: flex;
        color: #a7a4b7;

       

        &:hover,
        &.active {
            background-color: var(--primary-impact-color);
            color: #fff;
        }
    }

    .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active {
        background: var(--primary-impact-color);
        color: #fff;
    }
}