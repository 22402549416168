.profile-card{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    img{
        border-radius: 15px;
        width: 50px;

        @include MQ(SM){
            width: 35px;
        }
    }

    &.large{
        img{
            border-radius: 50px;
            width: 80px;
        }   
    }

    .info{
        display: flex;
        margin-left: 15px;
        flex-direction: column;

        .name{
            font-size: 16px;
            color: $title-color;
            font-weight: bold;
        }

        .subtitle{
            color: $primary-color-2;
            font-size: 12px;
            line-height: 11px;
            font-weight: normal;
        }

        .pretitle{
            color: $primary-color-2;
            font-size: 12px;
            line-height: 11px;
            margin-bottom: 3px;
        }

        .userName{
            margin-top: 2px;
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 500;
            color: #96a8b7;
        }
    }

    .profile-card-detail-btn{
        //background-color: $profile-btn-bg-color;
        margin-top: 13px;
        min-width: 100px;
        border: none;
    }

    .profile-menu-trigger{
        margin-left: 10px;
        background: none;
        border: none;
        font-size: 18px;
        cursor: pointer;
        outline: none;
        border: none;

        @include MQ(SM){
            margin-left: 5px;
        }
    }


}