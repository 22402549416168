.rate-with-emoji {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;

//   &:last-child{
//       margin-bottom: 0;
//   }

  .title {
    margin-bottom: 8px;
    color: $dark-title-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.21;
    color: #222831;
  }

  .emoji-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .transparent {
      transition: all 0.2s;
      opacity: 0.7;
      padding: 3px;
      border: solid 3px transparent;

      &:hover {
        transform: scale(1.3);
      }

      &.selected {
        opacity: 1;
        transform: scale(1.3);
        border: solid 3px $primary-color-2;
        border-radius: 30px;
      }
    }
  }
}
