body{
  .ant-layout{min-height: 100vh;}
  &:has(.application-environment){
    .ant-layout{min-height: calc(100vh - 40px);}
  }
}


.ant-layout-sider {
  background: transparent;

  @include MQ(SM){
    z-index: 999;
    position: absolute;
  }

  .menu-container {
    
    background-image: linear-gradient(252deg, #ffffff 37%, #ffffff);
    height: 100%;

    .ant-menu {
        background: transparent!important;
    }
    
    .logo {
      position: relative;
      height: 100px;
      img {
        height: 42px;
        padding-left: 0px;
        // width: 158px;
        margin: 20px 14px;
        position: absolute;
        top: 0;
        z-index: 1;
      }
      cursor: pointer;
      

      .effect-1 {
        left: 15px;
        //opacity: 0.2;
        position: absolute;
        z-index: 0;
        width: 100px;
        height: 120px;
        // background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0.51) 20%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 100%);
        background: url("/images/menu-effect-1.svg") no-repeat;
      }

      .effect-2 {
        right: 15px;
        //opacity: 0.2;
        position: absolute;
        z-index: 0;
        width: 90px;
        height: 95px;
        background: url("/images/menu-effect-2.svg") no-repeat;
      }
    }

    .effect-area {
        position: relative;
        height: 115px;

        .effect-3 {
          height: 62px;
          width: 200px;
          position: absolute;
          right: 0;
          top: 35px;
          background: url("/images/menu-effect-3.svg") no-repeat;
          z-index: 0;
        }
      }
  }

  .menu-info-box {
    .link-boxes {
      height: 100%;
      background-color: white;

      .link-box {
        padding: 20px;
        display: flex;
        height: 140px;
        overflow: hidden;
        position: relative;
        z-index: 0;

        .title {
          color: $title-color;
          font-size: 16px;
          line-height: 22px;
          font-weight: 700;
        }

        .description {
          color: $title-color;
          font-size: 12px;
          line-height: 15px;
          margin-top: 3px;
        }

        &.link-box-instructor {
          flex-direction: row;

          .first-col {
            width: 115px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }

          .second-col {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            img {
              height: 155px;
            }
          }
        }

        &.link-box-mentor {
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
          border-top: solid 1px $btn-default-bg-color;
          height: 200px;

          img {
            position: absolute;
            left: 8px;
            top: 11px;
            z-index: -1;
          }

          .first-row {
            width: 145px;
          }

          .second-row {
            margin-top: 10px;
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: space-around;

            button {
              margin-bottom: 5px;

              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}
