.gi-criteria-table {
  display: flex;
  flex-direction: column;

  .summary-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 140px;
    padding: 24px 30px;
    border-radius: 15px;
    border: solid 2px #7aba3e;
    background-color: #d8f7d4;

    .icon-container {
      width: 50px;
      height: 50px;
      display: flex;
      border: 5px solid rgba(255, 255, 255, 0.5);
      border-radius: 50px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        object-fit: contain;
        padding: 9px;
        background-color: #fff;
      }
    }

    .text-container {
      margin-left: 10px;
      .name {
        font-size: 15px;
        color: #1b262c;
        font-weight: bold;
      }

      .info {
        color: #006837;
        line-height: 1.54;
        font-size: 14px;
      }
    }

    &.has-err {
      border: solid 2px #ff3b46;
      background-color: #ffebec;

      .text-container {
        .info {
          color: #b2000a;
        }
      }
    }
  } // .summary-box

  .criteria-items {
    display: flex;
    flex-direction: column;
    // height: 315px;
    margin-top: 20px;
    padding: 20px 30px;
    border-radius: 15px;
    background-color: #ffffff;

    

      .table-title {
        font-size: 20px;
        font-weight: bold;
        color: #23374d;
        margin-bottom: 30px;
      }

      .criteria-item-container {
        
        .criteria-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 13px;
          font-weight: bold;
          color: #7aba3e;
          position: relative;
          margin-bottom: 40px;


          .description{
            // font-style: italic;
            color: $body-text-color;
            font-size: 11px;
            margin-top: 4px;
            opacity: 0.7;
          }

          @include MQ(SM){
            flex-direction: column;
          }

          .status{
            display: flex;
            flex-direction: row;
            .icon{
              margin-left: 5px;
            }

            @include MQ(SM){
              margin-top: 5px;
            }
          }

          &.has-err {
              color: #ff3b46;
            
          }

          &::after {
            content: " ";
            width: 100%;
            height: 1px;
            margin: 20px 0.1px 20px 0;
            background-color: #eceef4;
            position: absolute;
            left: 0;
            bottom: -40px;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }//criteria-item-container
    

  }

  .rank-confirm-box {
    position: relative;
    width: 100%;
    height: 140px;
    display: flex;
    padding: 24px 30px;
    border-radius: 15px;
    border: solid 2px #7aba3e;
    background-color: #d8f7d4;

    @media only screen and (max-width: 720px) {
      height: 140px;
    }

    .confirm-detail {
      margin-left: "20px";
      display: "flex";
      flex-direction: "row";
      width: 95%;
      .confirm-username {
        width: 120px;
        height: 18px;
        margin: 20px 652px 10px 30px;
        font-size: 13px;
        font-weight: bold;
        text-align: left;
        color: #1b262c;
        @media only screen and (max-width: 720px) {
          margin-left: 10px;
        }
      }
      .confirm-message {
        width: 90%;
        font-size: 13px;
        margin-left: 30px;
        color: #006837;

        @media only screen and (max-width: 720px) {
          width: 95%;
          margin-left: 10px;
        }

        @media only screen and (min-width: 721px) and (max-width: 1168px) {
          width: 90%;
        }
      }
    }
  }

  .askhr-button {
    margin-top: 30px;
    margin-left: -100px;

    @media only screen and (max-width: 720px) {
      display: none;
    }
  }

  .askhr-button-mobile {
    display: none;

    @media only screen and (max-width: 720px) {
      margin-top: 10px;
      justify-content: center;
      display: flex;
      background-color: #7aba3e;
    }

    @media only screen and (min-width: 721px) and (max-width: 1168px) {
      margin-top: 10px;
      padding-left: 100px;
      display: flex;
      background-color: #7aba3e;
    }
  }
}
