.home-slider{
    border-radius: 50px 50px 0 0; overflow: hidden;
    @media only screen and (max-width: 348px) { 
        padding-top: 80px !important; 
    }
    @media only screen and (max-width: 379px) {&{padding-top: 20px; }}
    @media only screen and (max-width: 640px) {&{border-radius: 0; }}
    .item{ position: relative;
        a{position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 15; font-size: 0; }
        img{ height: 520px; width: 100%; object-fit: cover;
            @media only screen and (max-width: 991px) {&
                {
                    height: auto;
                    object-fit:fill
                }}
        }
        // &::after{ content: ""; width: 100%; height: 50%;  position: absolute; top: 0; background-image: linear-gradient(#000, transparent);}
        //  &::before{ content: ""; width: 100%; height: 50%;  position: absolute; bottom: 0; background-image: linear-gradient(transparent, rgba(0,0,0,.25));}
        .caption{ background-color: #000; opacity: 0.6; position: absolute; z-index: 9; bottom: 30px; max-width: 90%; min-width: 50%; right: 0; padding: 15px; border-bottom: 2px solid #6c4900;
            @media only screen and (max-width: 800px) {                 
                bottom: 20px;
                padding: 12px;
            }
            @media only screen and (max-width: 600px) { 
                bottom: 15px;
                padding: 8px;
            }
            @media only screen and (max-width: 420px) { 
                bottom: 10px;
                padding: 6px;
            }
            @media only screen and (max-width: 348px) { 
                bottom: 5px;
                padding: 3px;
            }
            h2{font-size: 24px; color:#fff;
                @media only screen and (max-width: 800px) { 
                    padding: 12px;
                    font-size: 20px;
                }
                @media only screen and (max-width: 600px) { 
                    padding: 8px;
                    font-size: 16px;
                }
                @media only screen and (max-width: 420px) { 
                    padding: 6px;
                    font-size: 12px;
                }
                @media only screen and (max-width: 375px) { 
                    padding: 3px;
                    font-size: 10px;
                }
            }
        }
    }
    .slick-dots{position: relative; display: flex !important; justify-content: center; align-items: center; bottom: 20px; gap: 8px;
        li{width: 17px; height: 7px; border-radius: 6px; background: var(--primary-impact-color); opacity: .5; font-size: 0; display: block; transition: all .1s;
           button{font-size: 0; width: 100%; height: 100%; padding: 0;}
            &.slick-active{width: 12px; height: 12px; border: 1px solid var(--primary-impact-color); opacity: 1;background: none; justify-content: center; position: relative; align-items: center; display: flex;
                    &::before{content: ""; width: 6px; height: 6px; background-color: var(--primary-impact-color); display: block; position: absolute; border-radius: 100%; top: 2px;}
            }
        }
    }

}