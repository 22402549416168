.radio-selection-item{
    background-color: white;
    display: flex;
    width: 160px;
    height: 160px;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    border-radius: 20px;
    white-space: break-spaces;
    text-align: center;
    transition: all .2s;
    border: solid 3px white;
    font-weight: bold;
    margin-bottom: 8px;

    &.active, &:hover {
        border: solid 3px $primary-color-2;
        color: $primary-color-2;
    }

    .ant-radio{
        margin-top: 30px;
    }
}