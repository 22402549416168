.custom-menu-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border: solid 2px;
  padding: 14px 15px;
  color: white;
  gap: 9px;
  ::selection {
    background: transparent;
  }

  &[data-direction="column"] {
    flex-direction: column;
  }

  &[data-direction="row"] {
    flex-direction: row;
  }

  &:hover {
    border-color: #e2eb6f;
  }

  .icon {
    max-width: 20px;
  }

  .label {
    font-size: 14px;
  }

  .children{
      margin-top: 10px;
      display: flex;
      gap: 8px;
      flex-direction: column;
  }
}
