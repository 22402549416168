#feedback-app{z-index: 9;
    .app-panel{bottom: 40px; left:30px}
}
.feedback{
    .feedback-form{display: flex;flex-direction: column;justify-content: space-between; 
        textarea{margin-bottom: 20px} 
        .options{display: flex;flex-direction: row;justify-content: space-between;margin-bottom: 10px; 
            .option-emoji{cursor: pointer;width: 40px;height: 40px;transition: all .2s;filter: grayscale(100%); 
                &:hover, &.active{filter: grayscale(0%)}
            }
        }
    }
}