.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active{
  //  color: $menu-item-inactive-color !important;
  color: var(--primary-impact-color) !important
  //background-color: #8f9091 !important;
  // border-radius: 60px;
}
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  //color: $menu-item-inactive-color !important;
  color: var(--primary-impact-color) !important;
}

.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
  //color: $menu-item-inactive-color;
  color:var(--primary-impact-color)
}

.ant-menu {
  color: var(--primary-color);
  border: none;

  a {
    color:var(--primary-color);
    &:hover{
      //color: $menu-item-inactive-color !important;
      color: var(--primary-impact-color) !important;
      //font-weight: bold;
    }
    i{
      padding-right: 10px;
      color: $menu-item-inactive-color;
    }
  }



  .ant-menu-submenu-title {
    .icon {
      margin-right: 10px;
    }

    background-color: transparent;
    color: var(--primary-color);

    a {
      color: var(--primary-color);
    }
    i{
      padding-right: 10px;
      color: $menu-item-inactive-color;
    }
  }

  .ant-menu-item {

    .icon {
      margin-right: 10px;
    }

    &::after {
      width: 7px;
      height: 7px;
      border-radius: 60px;
      top: 18px;
      right: 10px;
      bottom: 0;
      border-right: none;
      background-color: white;
    }

    &.ant-menu-item-selected,
    &.ant-menu-item-active,
    .ant-menu-submenu-title {
      background-color: transparent;
      color: white;

      a {
        color: var(--primary-impact-color)
      }

      &::before {
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0;
        border-left: 3px solid white;
        background-color: white;
        content: '';
      }
    }


  }
}


.ant-menu-submenu:hover::after,
.ant-menu-submenu-open::after{
  //border-bottom: 2px solid $menu-item-inactive-color !important;
  border-bottom: 2px solid var(--primary-impact-color) !important;
}