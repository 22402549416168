.calendar-summary {
  display: flex;
  // width: 250px;
  height: 400px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  box-shadow: 0 6px 10px 0 rgba(35, 55, 77, 0.1);
  background-color: #ffffff;
  overflow: hidden;

  .header {
    background-color: $accent-color;
    height: 65px;
    color: white;
    padding: 23px 100px 23px 20px;
    position: relative;
    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.38;
      text-align: left;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: -25px;
      right: 35px;
      border-radius: 50px;
      background-color: $accent-color;
      border: solid 5px white;
      color: white;
      width: 50px;
      height: 50px;
    }
  }

  .body {
    padding: 20px;

    .calendar-summary-list-item {
      position: relative;
      margin-bottom: 30px;

      &::after {
        content: " ";
        position: absolute;
        height: 2px;
        width: 100%;
        bottom: -15px;
        background-color: #eceef4;
      }

      &:last-child {
        margin-bottom: 0px;
        &::after {
          display: none;
        }
      }

      .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.33;
        text-align: left;
        color: #96a8b7;
        margin-bottom: 10px;
      }

      .sub-title {
        font-size: 10px;
        font-weight: 500;
        line-height: 1.6;
        text-align: left;
        color: $primary-color-2;
      }

      .date-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .date {
          margin: 4px 10px 0 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.14;
          text-align: left;
          color: #23374d;
        }

        .time-range {
          margin: 4px 0 0 10px;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.14;
          text-align: left;
          color: #23374d;
        }

        .sep {
          width: 1px;
          height: 16px;
          margin: 4px 10px 0;
          border-radius: 1px;
          background-color: #eceef4;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: $primary-color-2;

    button{
        width: 100%;
        height: 100%;
        background-color: transparent;
        color: white;
        border: none;
        outline: none;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
    }
  }
}
