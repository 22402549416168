.custom-card{
    padding: 35px;
    display: flex;
    border-radius: 20px;
    box-shadow: 0 6px 10px 0 rgba(35, 55, 77, 0.1);
    background-color: white;

    &.custom-card-vertical{
        flex-direction: column;
        align-items: center;
        width: 250px;
        min-height: 400px;
    }

    &.full-width{
        width: 100%!important;
    }
}