.profile-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10px;

  @include MQ(SM) {
    flex-direction: column;
  }

  .left-col {
    flex: 3;
    display: flex;
    flex-direction: column;

    .first-row,
    .second-row .badges {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include MQ(SM) {
        flex-direction: column;
      }
    }
  }

  .right-col {
    display: flex;
    flex: 2;
    margin-left: 25px;

    @include MQ(SM){
        margin-left: 0px;
        margin-top: 20px;
    }

    .numbers {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;

      & > div {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.profile-strength {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 15px;
  gap: 8px;
  background-color: $profile-strength-bg-color;
  color: $profile-strength-text-color;

  &.sample-show{
    filter: grayscale(100%) blur(2px);
  }

  @include MQ(SM){
      margin-top: 10px;
  }

  .texts {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    .title {
      font-size: 20px;
    }

    .badge{
      height: 50px;
    }
  }


  .progress-bar{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .badge{
      height: 20px;
    }

    .percent-container{
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        width: 200px;
        background-color: white;
        border-radius: 10px;

        .percent-info{
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .percent-bar{
          background-color: #84c37b;
          height: 30px;
        }

        .badge-title{margin-left: 3px; font-size: 11px;}
    }
  }
}
