.ant-collapse{
    border:none!important;
    background-color: white!important;
    border-radius: 15px!important;

     
    .ant-collapse-item{
        border: none!important;
        border-bottom: none!important;
        border-radius: 15px!important;



        .ant-collapse-header{
            background-color: white;
            border-radius: 15px!important;
            padding: 16px 30px!important;
            // padding-right: 20px!important;


            @include MQ(SM){
                padding: 5px!important;
            }
    
            .ant-collapse-header-text{
                // display: inline-block;
                // width: 100%;
                font-size: 20px;
                font-weight: bold;
                color: #23374d;

                @include MQ(SM){
                    font-size: 15px;
                }
    
                .icon{
                    color: #40a3e9;
                }
            }
        }



        .ant-collapse-content{
            border: none;
            border-radius: 15px!important;

            .ant-collapse-content-box{
                padding: 16px 30px;


                @include MQ(SM){
                    padding: 5px!important;
                }
            }
        }
    }

    

   

    
}