.category-box-item{
    width: 90%;
    height: 250px;
    border-radius: 20px;
    background-color: white;
    position: relative;
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 20px;


    .icon-badge{
        
        border-radius: 20px;
        display: flex;
        justify-content: center;

        img{
            width: 100%;
            border-radius: 25px;
            // height: 125px;
        }

        .icon{
            color: white;
            position: absolute;
            bottom: 18px;
            right: 0;
            font-size: 24px;
        }
    }

    .title{
        color: $title-color;
        font-size: 15px;
        line-height: 24px;
        font-weight: bold;
        text-align: center;

        .soon{
            margin-top: 3px;
            font-size: 12px
        }
        
    }
}