.vote-content{ border: 1px solid #4259f5; border-radius: 5px; padding: 10px;
    p{color: #000; font-size: 13px;
        span{color: #666665;}
    }
    ul{ display: flex; justify-content: center; align-items: center; gap: 24px; margin-top: 5px;
        li{width: 24px; height: 24px; border-radius: 100%; border:1px solid #707070; color:var(--primary-impact-color); font-size: 12px; font-weight: 700; display: flex; justify-content: center; align-items: center;cursor: pointer;
            &.active,&:hover{background: var(--primary-impact-color); border-color: var(--primary-impact-color); color: #fff;}
        }
        &.disabled{
            li{pointer-events: none; cursor: not-allowed;}
        }
    }
}