.birthday-greeting{
    .template-list{ display: flex; overflow: auto; gap: 20px; padding-bottom: 10px;
        .item{ border: 1px solid #c45041; border-radius: 3px; cursor: pointer; transition: all .3s;
            img{width: 150px; height: 100px; object-fit: cover; display: block;border-radius: 3px;}
            &:hover{border-color: #ddd;}
        }
    }
    .preview-container{
        .canvas{width: 450px; height: 320px;  position: relative; display: flex; justify-content: center; align-items: center; overflow: hidden;
            @media only screen and (max-width: 992px) {&{margin:auto;}}
            @media only screen and (max-width: 540px) {&{width: 100%;}}
            img{ width: 100%; height: 100%; object-fit: contain;}
            h1{position: absolute; top: 40px; font-size: 22px; font-weight: 500;text-shadow: 0 0 10px #ffff, 0 0 10px #ffff, 0 0 20px #ffff, 0 0 30px #ffff, 0 0 40px #ffff, 0 0 50px #ffff;}
            h2{position: absolute; width: 300px; font-size: 14px; text-align: center; transform: translateY(-40px); text-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 50px #fff;}
        } 
    }
    .controls{display: flex; gap: 10px; width: 450px;
        @media only screen and (max-width: 992px) {&{margin:auto;}}
        @media only screen and (max-width: 540px) {&{width: 100%;}}
        .joystick {display: grid;grid-template-columns: repeat(3, 1fr);grid-template-rows: repeat(3, 1fr);grid-column-gap: 0px;grid-row-gap: 0px;    
            div:nth-child(1) { grid-area: 1 / 2 / 2 / 3; }
            div:nth-child(2) { grid-area: 3 / 2 / 4 / 3; }
            div:nth-child(3) { grid-area: 2 / 1 / 3 / 2; }
            div:nth-child(4) { grid-area: 2 / 3 / 3 / 4; }
            div:nth-child(5) { grid-area: 2 / 2 / 3 / 3; }
            div{display: flex; justify-content: center; align-items: center;
                .button{border: 1px solid var(--primary-icon-color); width: 32px;cursor: pointer; height: 32px; border-radius: 100%; display: flex; justify-content: center; align-items: center; color: var(--primary-icon-color);}
            }
        }
        .item{ border: 1px solid #d9d9d9; padding: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; flex: 1;
            h6{display: block; text-align: center; font-weight: 600; margin-bottom: 10px; }
        }
    }
    .form-group{ 
        @media only screen and (max-width: 992px) {&{margin:auto; width: 450px;}}
        @media only screen and (max-width: 540px) {&{width: 100%;}}
    }
    .ant-col{
        @media only screen and (max-width: 540px) {&{width: 100%;}}
    }
}

.rcp-light {
    --rcp-background: #ffffff;
    --rcp-input-text: #111111;
    --rcp-input-border: rgba(0, 0, 0, 0.1);
    --rcp-input-label: #717171;
  }

