.home-page-main{
    .ant-col{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .col-firstPanel{
        .ant-col{
            @media only screen and (max-width: 991px) {
                margin-top: 0;
                padding-left: 20px !important;
                padding-right: 20px !important;
            }
            @media only screen and (max-width: 767px) {
                margin-top: 0;
                padding-left: 0 !important;
                padding-right: 0 !important;
                margin-top: 20px !important;
            }
        }
    }
}

.ourNews{
    .ant-col{
        padding-left: 0px !important;
        padding-right: 15px !important;
        padding-top: 10px !important;
    }
}