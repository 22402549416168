.thank-you-widget{ 
    width: 100%; 
    height: 100%; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-between; 
    // gap: 10px !important;
    //  border: solid; border-color: #669df6; border-radius: 27px; padding-top: 15px;
    .widget-header{display: flex; justify-content: center; 
        img{padding-top: 5px;}
    }
    .thankYouSpace{
        gap:none !important;
    }
    .widget-body{ display: flex; flex-direction: column; gap: 4px; justify-content: center; align-items: center; padding-top: 10px;
        @media only screen and (max-width: 991px) {&{ justify-content: space-around;}}
        .item{ display: flex; flex-direction: column; justify-content: center; 
            h3{color: #1f1f1f; font-size: 14px; margin-bottom: 15px; font-weight: 600;}
            .avatars{display: flex; gap: 17px; padding-bottom: 10px;
                .ant-avatar{width: 40px; height: 40px; cursor: pointer;}
            }
        }
    }
    .widget-footer{
        .ant-btn{color: var(--primary-icon-color); font-size: 12px; border: 1px solid var(--primary-icon-color); background: none; border-radius: 22px; width: 100%; height: 41px;}
    }
}
.thanks-modal{
    @media only screen and (max-width: 768px) {&{max-width: calc(100vw - 100px);}}
    @media only screen and (max-width: 640px) {&{max-width: calc(100vw - 40px);}}

    .ant-select-selection-item,.ant-select-selection-overflow{height: 40px;}
    .ant-select-selector{height: 45px}
    .newIdeaTypes{
        height: 55px !important;
        .ant-select-selector{height: 55px !important}
    }
      
    .modalInfoMessage{
        @media screen and (max-width: 500px){
            padding-top: 12px;
        }
    }  
    .newIdeaTypes {    
        .ant-select-selector{
        @media screen and (max-width: 500px){
            align-items: start;
        }
        @media screen and (max-width: 447px){
            height: 65px !important;
        }
        }
        .ant-select-selection-overflow-item{
        @media screen and (max-width: 500px){
            padding: 0;
        }
        .ant-select-selection-item{
            @media screen and (max-width: 500px){
            line-height: 20px;
            height: 20px;
            }
        }
        }
    }
    .ant-modal-footer{display: none;}
    .ant-modal-close{position: absolute; background: #c45041; width: 33px; height: 33px; top:-15px ; right:-15px; border-radius: 100%; display: flex; justify-content: center; align-items: center;color: #fff;
        &:hover{color:#fff}
    }
    .ant-modal-content{border-radius: 4px !important;}
    .ant-modal-header{width: max-content; border-radius: 4px 4px 0 0 !important; top: -50px; position: absolute; border:none; padding:15px;
        .ant-modal-title{color: #4f5769; font-size: 14px; font-weight: 700;}
    }
    p{color: #4f5769; font-size: 14px;}
    h3{color: var(--primary-icon-color); font-size: 14px; font-weight: bold; margin-top: 25px; margin-bottom: 5px;}
    .newIdeaTitle{color: var(--primary-icon-color); font-size: 14px; font-weight: bold; margin-top: 25px; margin-bottom: 5px;}
    .newIdeaTitleRight{color: var(--primary-color); font-size: 12px; font-weight: bold; margin-top: 25px; margin-bottom: 5px; padding-left: 5px;}
    input{font-size: 15px; height: 40px;border-radius: 0 !important; 
        &:focus,&:hover{box-shadow: none !important; border-color: #cecece !important; }
        &[disabled]{background: none !important;
            &::placeholder{color: #c45041;}
        }        
    }
    .ant-select{font-size: 15px; height: 40px;border-radius: 0 !important;  width: 100%;
        .ant-select-selector{height: 40px; border-radius: 0 !important;}
        .ant-select-selection-item{line-height: 40px; color:rgba(0, 0, 0, 0.85) !important;}
        .ant-select-selection-search-input{height: 40px !important;}
        &:focus,&:hover{box-shadow: none !important; border-color: #cecece !important; 
            .ant-select-selector{border-color: #cecece !important; box-shadow: none !important;}
        }
        &[disabled]{background: none !important;
            &::placeholder{color: #c45041;}
        }    
        .ant-select-selection-placeholder{line-height: 38px;}
        &.ant-select-disabled{
            .ant-select-selector{background: none !important; 
                .ant-select-selection-placeholder{color: #c45041 !important; line-height: 38px;}
            }

        }
    }
    .search-person{ margin-top: 22px;
        .ant-select{width: 100%;}
        .anticon-search{font-size: 20px; color: #4f5769;}
        .ant-input-group{border: 1px solid #cecece; border-radius: 20px; overflow: hidden;}
        .ant-input-group-addon{background: none !important; border: none !important;}
        input{ border:none !important; 
            &::placeholder{ color: rgba(79, 87, 105,.75); font-style: italic; font-weight: 600;}
        }
        .ant-btn{background: var(--primary-icon-color); height: 40px;border: 1px solid var(--primary-icon-color); position: relative; right: -2px; border-radius: 20px !important; color: #fff;}
    }
    .end{display: flex; justify-content: flex-end; margin-top: 20px;
        .ant-btn{border: 1px solid var(--primary-icon-color); height: 40px; border-radius: 20px; color:var(--primary-icon-color); font-size: 14px; background: none !important; font-weight: 500; padding-right: 30px; padding-left: 30px;}
    }
    .selected-person{ border: 1px solid #cecece; padding: 7px 11px; 
        span{color: #c45041; font-size: 15px;}
        ul{ display: flex; flex-direction: column; gap: 10px;
            li{display: flex; justify-content: space-between; align-items: center; font-size: 15px; border-bottom: 1px solid #ddd; padding-bottom: 10px; font-weight: 500;
                &:nth-last-child(1){border:none; padding-bottom: 0;}
            }
        }
    }
    .ant-tabs-nav-list{ 
        .ant-tabs-ink-bar{display: none;}
        .ant-tabs-tab{ padding: 0 !important; flex: 1; margin: 0 !important; border: 1px solid #c9c9c9 !important; background: none !important;
            .ant-tabs-tab-btn{ display: flex; width: 100%; justify-content: center; align-items: center; padding: 10px 0;
                .btn{color: #888888; font-size: 14px; font-weight: 400;
                    strong{color: #4f5769; font-weight: 600;}
                }
            } 
            &.ant-tabs-tab-active{
                .ant-tabs-tab-btn{ 
                    .btn{color: #fff !important;
                        strong{color: #fff !important;}
                    }
                } 
            }
        }
    }
    .thanks-details{ display: flex; flex-direction: column; gap: 5px;
        .item{display: flex; align-items: center; justify-content: space-between; cursor: pointer;
            & > div{display: flex; align-items: center; gap: 10px;}
            .avatar{ 
               .ant-avatar{width: 34px; height: 34px;border: 2px solid #09eac7;}
            }
            .name{color: #4f5769; font-size: 14px; font-weight: 500;}
            .description{color: #4f5769; font-size: 14px; font-weight: 500;}
        }
    }
    .ant-pagination{ display: flex; justify-content: center; align-items: center;margin:15px 0;
        li{width: auto; height: auto; min-height: auto; min-width: auto; line-height: 1; padding: 0; margin: 0; border:none;
            &.ant-pagination-prev, &.ant-pagination-next{margin: 0 10px;}
            a{color: #4f5769; font-size: 14px; font-weight: 500;}
            &.ant-pagination-item-active{
                a{ color: var(--primary-impact-color);}
            }
            &.ant-pagination-disabled{
                a{ opacity: .6;}
            }
            
        }

    }

    .ant-tabs-nav-list{
        @media only screen and (max-width: 640px) {&{   flex-direction: column;}}
     }
}
