.event-calendar {
    width: 100%;
    height: 100%;
    padding: 20px 20px;

    .event-calendar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            margin: 9.7px 40px 25.8px 10px;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
            text-align: left;
            color: #000;
        }

        .icon {
            margin: 0 10px 13.1px 0;
        }

       
    }
}

.event-tab {

    .ant-tabs{
        overflow: inherit!important;
      }
      .ant-tabs-tabpane{
        overflow: auto;
      }
      
      .ant-tabs-nav-list {
        flex: 1;
        justify-content: space-around;
      
        .ant-tabs-ink-bar {
          background: #10a3e9 !important;
        }
      
        .ant-tabs-tab {
          padding: 12px 15px;
        }
      
        .ant-tabs-tab-btn {
          font-size: 16px;
          font-weight: bold;
        }
      
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #fff;
          background: var(--primary-impact-color);
          flex-direction: column;
        }
      }
      
      .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
        width: 30px;
        height: 40px;
        background: #fff;
        border: 0px;
        border-radius: 12px !important;
        justify-content: center;
        display: flex;
        color:  #a7a4b7;
        
        .ant-tabs-tab-active{
          background: var(--primary-impact-color);
          color: #fff;
        }
        
        &:hover, &.active {
          background-color: var(--primary-impact-color);
          color: #fff;
        }
      }
      
      .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
        background: var(--primary-impact-color);
        color: #fff;
      }

}