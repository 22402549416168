.corporate-items{
    .ant-col{
        padding-bottom: 20px;
    }
    .corporate-item-list{
        .header-info{
            display: flex;
            align-items: center;
            cursor: pointer;
            h2{
                padding-left: 10px;
                font-weight: bold;
            }
        }
        .thumbnail-photo{
            height: 200px;
            position: absolute;
        }
        .thumbnail-photo:hover{
            opacity: 0.2;
            transition: 0.5s;
        }
        .ant-card-cover{
            .cover-image{
                position: absolute;
                height: 200px;
            }
            .cover-image:hover{
                opacity: 0.2;
                transition: 0.5s;
            }
        }
    }
}