.consultant-container{
    display: flex;
    flex-direction: column;
    height: 100%;

    @include MQ(SM){
        margin-top: 20px;
    }

    &.red{
        .box-title{
            background-color: $accent-color;
            .consultant-pic{
                border-color: $accent-color;
            }
        }

        .info{
            a,.title{color: $accent-color;}
        }
    }
    
    &.blue{
        .box-title{
            background-color: $primary-color-2;
            .consultant-pic{
                border-color: $primary-color-2;
            }
        }

        .info{
            a,.title{color: $primary-color-2;}
        }
    }

    .box-title{
        position: relative;
        background-color: $accent-color;
        width: 100%;
        height: 70px;
        padding: 15px 15px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        @include border-radius-top(15px);

        .tabs{
            display: flex;
            flex-direction: column;
            width: 70%;

            button{
                transition: all .2s;
                margin-bottom: 3px;
                font-size: 12px;
                border: solid 1px transparent;
                padding: 3px;
                border-radius: 10px;

                &:last-child{
                    margin-right: 0px;
                }

                &.selected{
                    border: solid 1px white
                }
            }

            
        }

        .consultant-pic{
            position: absolute;
            width: 60px;
            border: solid 5px $accent-color;
            right: 20px;
            bottom: -25px;
            border-radius: 50px;
        }
    }

    .info{
        width: 100%;
        height: 100px;
        @include border-radius-bottom(15px);
        padding: 20px 15px;

        .name{
            color: $title-color;
            font-size: 19.5px;
        }

        .title{
            color: $primary-color-2;
            font-size: 12px;
            margin-top: 5px;
        }

        .description{
            font-size: 12px;
            line-height: 1.42;
            margin-top: 10px;

            a{
                font-size: 10.5px;
                color: $body-text-color;
            }
        }
    }
    
}