.circle-name {
  display: flex;
  flex-direction: row;
  align-items: center;

  .circle {
    width: 30px;
    height: 30px;
    border-radius: 10px;
    text-align: center;
    color: #23374d;
    font-size: 12px;
    background-color: #eceef4;
    line-height: 30px;

  }

  .name-title-container{

    display: flex;
    flex-direction: column;
    margin-left: 15px;

    .name{
      font-size: 13px;
      font-weight: bold;
      text-align: left;
      color: #1b262c;
      
    }

    .title{
      font-size: 10px;
      color: #96a8b7;
    }
  }


  &.selected, &:hover{

    .circle{
      background-color: #ff3b46;
      color: white;
    }


    .name-title-container{
      .name{
        color: #ff3b46;
      }

      .title{
        color: #006837;
      }
    }
    
}

  
}
