.information-popup{
    .ant-modal-close-x{display: none;}
    .ant-modal-header{border:none;}
    .image{
        img{width: 100%;}
    }
    .content{
        p{font-size: 14px; padding-bottom: 15px;}
        img{max-width: 100% !important;}
    }
    .information-popup-footer{display: flex; justify-content: flex-end; align-items: center; gap: 20px;
        .check{display: flex; align-items: center; gap: 5px; cursor: pointer;
            label{cursor: pointer;}
        }
    }
}    