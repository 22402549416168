.ant-layout-footer {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  justify-content: end;
  color: $footer-text-color;
  align-items: center;
  padding: 10px;
  padding-right: 50px;

  @include MQ(SM){
    flex-direction: column;
  }

  .left-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include MQ(SM){
      align-items: center;
    }

    .logo{
      height: 45px;
    }

    .copyright{
      margin-top: 14px;
      font-size: 12px;
    }

  }

 

  .right-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 100%;
    text-align: right;
    align-items: center;

    @include MQ(SM){
      flex-direction: column;
    }
    
    span{
      color: #98adb4;
      font-size: 16px;

      @include MQ(SM){
        margin: 10px 0;
      }
    }

    .sep{
      margin: 0 30px;
      height: 30px;
      width: 1px;
      background-color: #98adb4;

      margin-right: 10px;

      @include MQ(SM){
        height: 1px;
        width: 90%;

        margin: 10px 0;
      }
    }

    .menu {
        display: flex;
        justify-content: space-around;

        @include MQ(SM){
          flex-direction: column;
        }

        img{
          height: 50px;
          margin-right: 10px;

          @include MQ(SM){
            margin-right: 0;
            margin-bottom: 5px;
          }

          &:last-child{
            margin-right: 0px;
          }
        }
    }
  }
}
