.corporate-asset{
    background-color: #f0f4f7 !important;

    .intranet-content-card-body{
        padding-right:  20px;
        .ant-tabs-nav-list{
            justify-content: flex-start !important;
            .ant-tabs-tab-active{
                background: transparent !important;
                .ant-tabs-tab-btn{                        
                    color:var(--primary-icon-color) !important;
                }
            }
        }
    }
    
    .ant-tabs {overflow: inherit !important;flex: 1;}
    .ant-tabs-tabpane {overflow: auto; } 
    .ant-tabs-nav {
        // display: flex !important;
        &:before{bottom: 0px;}
    }
    .ant-tabs-nav-list {flex: 1;justify-content:flex-start;
        .ant-tabs-tab{ background: none !important; padding: 0 !important; margin: 0 !important;
            .ant-tabs-tab-btn {font-size: 15px;font-weight: normal;padding: 12px 15px; color: #666665; border-bottom: 2px solid transparent;}
            &.ant-tabs-tab-active{
                .ant-tabs-tab-btn{border-color: var(--primary-icon-color); color: #000;}
            }
        }
    }
    .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {display: flex;align-items: center;justify-content: space-between;background-color: #fff;border: 0;color: #666665;
        &:hover,&.active {}
    }

    .corporateBody{
        height: auto;
        color: var(--primary-color);
        position: relative;
    }
    .corporateButtons{
        background-color: #bfe2ea;
        position: absolute;
        top:10px;
        border: 2px solid var(--primary-color);
        z-index: 10;
        font-size: 16px;
        &.right{
            right: 10px;
        }
        &.left{            
            left: 10px;
        }
    }
}