
$sm: 480px;
$md: 768px;
$lg: 992px;
$xl: 1140px;

// media queries

@mixin MQ($canvas) {
    @if $canvas == SM {
        @media only screen and (max-width: $sm) {
            @content;
        }
    } @else if $canvas == MD {
        @media only screen and (max-width: $md) {
            @content;
        }
    } @else if $canvas == LG {
        @media only screen and (max-width: $lg) {
            @content;
        }
    } @else if $canvas == XL {
        @media only screen and (max-width: $xl) {
            @content;
        }
    } @else if $canvas == 'SM-MD' {
        @media (min-width: $sm) and (max-width: $md) {
            @content;
        }
    } @else if $canvas == 'MD-LG' {
        @media (min-width: $md) and (max-width: $lg) {
            @content;
        }
    } @else if $canvas == 'LG-XL' {
        @media (min-width: $lg) and (max-width: $xl) {
            @content;
        }
    } @else if $canvas == IE {
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            @content;
        }
    }
}

@mixin border-radius($value) {
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
    border-radius: $value;
}

@mixin border-radius-top($value) {
    -webkit-border-top-right-radius: $value;
    -webkit-border-top-left-radius: $value;
    -moz-border-radius-topright: $value;
    -moz-border-radius-topleft: $value;
    border-top-right-radius: $value;
    border-top-left-radius: $value;
}

@mixin border-radius-right($value) {
    -webkit-border-top-right-radius: $value;
    -webkit-border-bottom-right-radius: $value;
    -moz-border-radius-topright: $value;
    -moz-border-radius-bottomright: $value;
    border-top-right-radius: $value;
    border-bottom-right-radius: $value;
}

@mixin border-radius-bottom($value) {
    -webkit-border-bottom-right-radius: $value;
    -webkit-border-bottom-left-radius: $value;
    -moz-border-radius-bottomright: $value;
    -moz-border-radius-bottomleft: $value;
    border-bottom-right-radius: $value;
    border-bottom-left-radius: $value;
}

@mixin border-radius-left($value) {
    -webkit-border-top-left-radius: $value;
    -webkit-border-bottom-left-radius: $value;
    -moz-border-radius-topleft: $value;
    -moz-border-radius-bottomleft: $value;
    border-top-left-radius: $value;
    border-bottom-left-radius: $value;
}

/*Border radius ends here*/

@mixin transform($value) {
    -webkit-transform: $value;
    -moz-transform: $value;
    -ms-transform: $value;
    -o-transform: $value;
    transform: $value;
}

@mixin box-shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}

@mixin transition($value) {
    -webkit-transition: $value;
    -moz-transition: $value;
    -o-transition: $value;
    transition: $value;
}

@mixin rotate($value) {
    -webkit-transform: rotate($value);
    transform: rotate($value);
}

@mixin gradient($value, $value2) {
    background: -webkit-linear-gradient($value, $value2);
    background: -moz-linear-gradient($value, $value2);
    background: -o-linear-gradient($value, $value2);
    background: linear-gradient($value, $value2);
    -webkit-transition: background 3s ease-out;
    -moz-transition: background 3s ease-out;
    -o-transition: background 3s ease-out;
    transition: background 3s ease-out;
}

@mixin gradient-center($value, $value2, $value3) {
    background: -webkit-linear-gradient($value, $value2, $value3);
    background: -moz-linear-gradient($value, $value2, $value3);
    background: -o-linear-gradient($value, $value2, $value3);
    background: linear-gradient($value, $value2, $value3);
    -webkit-transition: background 3s ease-out;
    -moz-transition: background 3s ease-out;
    -o-transition: background 3s ease-out;
    transition: background 3s ease-out;
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content
    }
    :-moz-placeholder {
        @content
    }
    ::-moz-placeholder {
        @content
    }
    :-ms-input-placeholder {
        @content
    }
}


$baseline-px: 10px;

@mixin rem($property, $px-values) {
  // Convert the baseline into rems
  $baseline-rem: $baseline-px / 1rem * 1;
  // Print the first line in pixel values
  #{$property}: $px-values;
  // If there is only one (numeric) value, return the property/value line for it.
  @if type-of($px-values) == "number" {
    #{$property}: $px-values / $baseline-rem; }
  @else {
    // Create an empty list that we can dump values into
    $rem-values: ();
    @each $value in $px-values {
      // If the value is zero or not a number, return it
      @if $value == 0 or type-of( $value ) != "number" {
        $rem-values: append($rem-values, $value); }
      @else {
        $rem-values: append($rem-values, $value / $baseline-rem); } }
    // Return the property and its list of converted values
    #{$property}: $rem-values; } }