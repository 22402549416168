.logos{
    display: flex;
    justify-content: space-around;
    // margin-right: -64px;
    // margin-left: -64px;
    padding: 65px;
    background-color: $btn-default-bg-color;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;

    @include MQ(SM){
        flex-direction: column;
    }

    .logo-item{
        font-size: 26px;
        line-height: 41px;
        font-weight: bold;

        @include MQ(SM){
            margin-bottom: 50px;
            text-align: center;

            &:last-child{
                margin-bottom: 0;
            }
        }

        img{
            height: 30px
        }
    }
}