body{background:#fff !important;
   &>iframe:nth-last-child(1){display: none !important;}
}
header{
    // padding-left: 10px;
    position: fixed;
    top: 0;
    background-color: #fff; /* Header'ın arka plan rengini ayarlayın */
    z-index: 1000; /* Header'ın diğer içeriklerin üzerinde olmasını sağlamak için */
    width: 100%;

  .header-top{ display: flex; align-items: center; justify-content: flex-start; padding-top: 10px;
    .menu-btn{display: flex; gap: 10px; padding: 20px; cursor: pointer;border-right: 1px solid #e3e3e3;
      @media only screen and (max-width: 1100px){
        border-right: 0px solid #e3e3e3;
      }
      @media only screen and (max-width: 500px) {&{padding: 15px; }}
      .icon{ display: flex; flex-direction: column; color: var(--primary-icon-color);}
      .text{color: #000; font-size: 15px;
        @media only screen and (max-width: 500px) {&{display: none; }}
      }
    }
    .header-bar{ 
      width: 100%; display: flex; align-items: center; justify-content: space-between; padding-left: 20px; z-index: 1001; flex-wrap: wrap;
      // padding-left: 10px;
      // position: fixed;
      // top: 0;
      // background-color: #fff; /* Header'ın arka plan rengini ayarlayın */
      // z-index: 1000; /* Header'ın diğer içeriklerin üzerinde olmasını sağlamak için */
    
      .search-container{ flex:1; display: flex; transition: width .5s; width: 0; border: 1px solid transparent; padding: 0 10px; border-radius: 6px; align-items: center; position: relative; padding-left: 50px;
        @media only screen and (max-width: 1000px) {&{padding: 0; }}
        .icon{font-size: 22px; color: var(--primary-icon-color); cursor: pointer;}
        .search-area{ width: 100%; display: none;
          .search-results{position: absolute; background: #fff; left: -1px; width: calc(100% + 2px);border: 1px solid #e5e5e5; border-top: none; padding: 30px; z-index: 999999; border-radius: 0 0 6px 6px; top: 30px; 
            .short-searchs{ border-bottom: 1px solid #e5e5e5; padding-bottom: 10px;
              h3{color: var(--primary-impact-color); margin-bottom: 5px;}
              ul{display: flex;gap:5px;flex-wrap:wrap;
                li{cursor: pointer; border: 1px solid #e5e5e5; border-radius: 6px; padding: 3px;}
              }
            }
            .loading{display: flex; justify-content: center; align-items: center; flex-direction: column; gap: 5px; padding: 15px 0;
              span{font-size: 14px; color: var(--primary-impact-color);}
            }
            .list{ display: flex; flex-direction: column; gap: 10px;
              .result-group{ 
                .result-group-header{ display: flex; justify-content: space-between; align-items: center; padding: 15px 0; border-bottom: 1px solid #e5e5e5;
                  h5{font-size: 18px; font-weight: 600;color: var(--primary-impact-color);}
                  a{color: var(--primary-impact-color); }
                }
              }
              ul{padding: 0;
                li{cursor: pointer; color:#4b4b4b; padding: 10px 13px; transition: all .3s; margin: 1px 0; font-size: 14px;
                  &:hover{background: #f5f5f5;}
                  &.user{display: flex; align-items: center; gap: 10px; padding: 4px 13px;}
                  .title{font-weight: 600; margin-bottom: 5px; display: block;}
                  .description{
                    *{font-size: 12px; line-height: 1;}
                  }
                }
              }
            }
          }
        }
        &.show{width: 100%; border-color: #e5e5e5; margin-right: 20px;
          .search-area{display: block; 
            input{width: 100%; padding: 10px; border: none; font-size: 14px;}
           
          }
        }
        .search-info-text{
          color: #9C9DA1;
          font-size: 12px;
          padding: 5px;
          vertical-align: middle;
          &.hide{
            display: none;
          }
        }
      }
      .logo{
        a{ display: block;
          img{height: 36px;}
        }
      }
      .user-profile{
        .avatar-container{ display: flex; align-items: center; padding: 5px 40px 5px 20px; gap: 10px;
          @media only screen and (max-width: 500px) {&{padding: 5px 15px 5px 0px; }}
          .avatar{ width: 48px; height: 48px; cursor: pointer; position: relative;
            img{ width: 48px; height: 48px; border-radius: 100%; border: 2px solid var(--primary-impact-color); padding: 2px; }
            .mood{ position: absolute; right: -6px; bottom: -6px; width: 28px; height: 28px; background: #fff; display: flex; justify-content: center; align-items: center;  font-size: 20px; border-radius: 100%;
              img{border: 2px solid #fff; width: 24px; height: 24px; padding:0}
            }
            .officeType{border: 1px solid var(--primary-impact-color); position: absolute; right: -6px; bottom: -6px; width: 28px; height: 28px; background: rgba($color: (#ffffff), $alpha: 1); display: flex; justify-content: center; align-items: center;  font-size: 20px; border-radius: 100%; 
              i{width: 24px !important; padding-left: 2px;  }
            }
          }
          .settings{
            span{color: #666665; font-size: 22px;cursor: pointer;}
          }
        }
        .anticon-bell{
          font-size: 27px;
          padding: 10px;
        }
        .count{background: #ff4e4e;width: 15px; height: 15px; border-radius: 100%; display: inline-flex; justify-content: center; align-items: center; color: #fff; transform: translate(-5px,-5px); font-weight: 600; font-size: 10px; margin-left: -15px;}

      }
      .ant-switch-checked {
        background-color: rgba(0, 0, 0, 0.25);
     }
    }
    @media screen and (max-width: 1100px) {
      .ant-layout-content{
        margin-top: 200px;
      }
      .header-bar {
        flex-direction: column; /* Ekran genişliği 600 pikselden küçük olduğunda öğeler dikey olarak düzenlenir */
      }
      .search-container 
      {
        margin-top: 20px !important;
        width: 50% !important; /* Ekranın tamamını kaplayacak şekilde genişletilir */
        justify-content: space-evenly;
      }
      .user-profile{
        margin-top: 20px !important;
        display: flex;
        flex-wrap: wrap;    
        justify-content: center;
        align-items: center;
        width: 100% !important; /* Ekranın tamamını kaplayacak şekilde genişletilir */
        @media screen and (max-width: 700px) {

          .welcome-screen
          {
            display: none;
          }
          .langSelectorText
          {
            display: none;
          }
          .officeSelectorText
          {
            display: none;
          }          
        }
      }
    }
    @media screen and (max-width: 700px) {
      .search-container 
      {
        width: 100% !important; /* Ekranın tamamını kaplayacak şekilde genişletilir */
      }
    }
  }
  
  
  .header-bottom{ border-bottom: 1px solid #e3e3e3; border-top:  1px solid #e3e3e3; height: 46px;
    @media only screen and (max-width: 640px) {margin-left: 50px;}
    ul{ display: flex; justify-content: center; align-items: center;flex-wrap:wrap; width: 100%;
      // @media only screen and (max-width: 768px) {&{display: grid; grid-template-columns: repeat(2,1fr); }}
      @media only screen and (max-width: 640px) {&{grid-template-columns: 1fr; }}
      li{
        @media only screen and (max-width: 640px) {&{display: flex; justify-content: center; }}
        a{color: var(--primary-icon-color); font-size: 14px; display: flex; align-items: center; gap: 10px; padding: 10px 15px; transition: all .3s;
          i{color: var(--primary-impact-color);}
          span{font-size: 20px;}
          .count{background: #ff4e4e;width: 15px; height: 15px; border-radius: 100%; display: inline-flex; justify-content: center; align-items: center; color: #fff; transform: translate(-5px,-5px); font-weight: 600; font-size: 10px;}
          .anticon-caret-down{color: #c4c4c4; font-size: 12px;}
          &:hover,&.active{color: var(--primary-impact-color);}
        }
      }
    }
    .mega-menu{background: rgba(23,25,23,0.9);width: 100%;/* min-height: calc(100vh - 99px);*/ padding: 30px 30px 0px 30px; position: absolute; z-index: 9; top: -100%; transition: all .8s; opacity: 0;
      &.show{top: 95px; opacity: 1; padding-left: 70px;;
        @media only screen and (max-width: 768px) {&{top:55px; }}
      }
      .mega-menu-content{
        .mega-menu-header{ display: flex; justify-content: space-between; align-items: center;  margin-bottom: 25px;
          h2{color:#f6fff2; font-size: 29px; font-weight: 500;}
          .close-btn{color: #fff; font-size: 16px; cursor: pointer; display: flex;}
        }
        ul{ display: grid; grid-template-columns: repeat(3,1fr); column-gap: 80px;
          @media only screen and (max-width: 1024px) {&{column-gap: 20px;}}
          @media only screen and (max-width: 800px) {&{grid-template-columns: repeat(2,1fr);}}
          @media only screen and (max-width: 640px) {&{grid-template-columns: repeat(1,1fr);}}
          li{ border-bottom: 1px solid #707070;
            a{padding: 25px 0; font-size: 15px; color: #fff; display: flex; justify-content: space-between; align-items: center; }
          }
        }
      }
    }
  }
}
body{
  &:has(.run-as-different){
    header .header-bottom .mega-menu.show{top: 145px;
       @media only screen and (max-width: 768px) {&{top:110px; }}
    }
  }
}


footer{ background: #f7f7f7; display: block; padding: 10px; border-top: 2px solid $primary-color-2; padding-left: 70px; position: relative; z-index: 100;
  @media only screen and (max-width: 480px) {&{padding: 20px; }}
  .footer-wrapper{ 
    // max-width: 1440px;
    //  margin: auto; 
     display: flex; justify-content: space-between; align-items: center;
    @media only screen and (max-width: 480px) {&{flex-direction: column; gap: 15px; }}
    .logo{
      a{
        // img{ max-width: 150px;}
      }
    }
    .copyright{ display: flex; flex-direction: column; align-items: flex-end; gap: 8px;
      @media only screen and (max-width: 480px) {&{align-items: center; }}

      a{color: $primary-color-2;}
      span{ color: #000;font-size: 12px;}
    } 
  }
}

.corporate-card-cutsom{
  display: flex;
  justify-content: center;
}

div.intranet-content-card-body.ourNews > div > div > div > div > div{
  @media only screen and (max-width: 990px){
    border-bottom:1px solid var(--primary-divider-color) !important;    
  }
}
div.intranet-content-card-body.ourNews > div > div > div > div > div:last-child,div.intranet-content-card-body.ourNews > div > div > div > div > div:nth-last-child(2){
  @media only screen and (max-width: 990px){
    border-bottom:0px solid var(--primary-divider-color) !important;    
  }
}

div.intranet-content-card-body.ourNews > div > div > div > div > div:nth-last-child(2){
  @media only screen and (max-width: 768px){
    border-bottom:1px solid var(--primary-divider-color) !important;    
  }
}

.ant-list.ant-list-split{
  width: 100%;
}

.my-tasks-dropdown{background: #fff; box-shadow:  0 3px 6px 0 rgba(0, 0, 0, 0.15); border-radius: 6px; padding: 0 15px; width: 300px; max-height: 200px; overflow: auto;
  .ant-list-item{cursor: pointer;
    .ant-list-item-meta-title{
      span{background: #ff4e4e;width: 15px; height: 15px; border-radius: 100%; display: inline-flex; justify-content: center; align-items: center; color: #fff; transform: translate(5px,-5px); font-weight: 600; font-size: 10px;}
    }
    .ant-list-item-meta-description{font-size: 12px; line-height: 1;}
  }
}
.user-profile-dropdown{ background: #fff; box-shadow:  0 3px 6px 0 rgba(0, 0, 0, 0.15); border-radius: 6px; padding: 0 15px; width: 240px;
  .item-list{
    .item{display: flex; align-items: center; border-bottom: 1px solid #e9e9ed; padding: 15px 0; cursor: pointer; gap: 10px;
      .icon{width: 40px; height: 40px; border-radius: 5px; display: flex; justify-content: center; align-items: center;
        &.green{background: #f3fcf4; color: #55b560;}
        &.red{ background: #fdebeb;color: #d54545;}
        &.yellow{ background: #fdf8eb;color:#ffb822}
        &.gray{background: #e9ebef; color:#b9b9b9}
        &.blue{background: #e9ebef; color:#2a95b6}
        &.officeIcon{ width: 52px; i{scale:1.5}}
        &.moodIcon{ span{scale:1.5}}
      }
      .text{color: #606060; font-size: 14px; display: flex; gap: 10px; align-items: center;
        a{ display: flex; align-items: center; gap: 5px; color: #606060;
          span{color: #606060;}
          img{width: 24px;}
        }
      }
      &:nth-last-child(1){border:none}
      &.with-qr{justify-content: space-between;
        .menu-detail{ display: flex; align-items: center; gap: 10px; width: 100%;}
        .icon{font-size: 34px;}
      }
    }
  }
  .profile-content{ padding:  30px 0;
    .action{cursor: pointer;}
    h2{color: #d54545; font-size: 20px; font-weight: 700;}
    h3{color: #000; font-size: 14px; font-weight: 500; line-height: 25px;}
    hr{border-color: #e9e9ed;}
    a{color: #457ce5; font-size: 13px; text-decoration: underline;}
  }

}
.mood-list{display: grid; grid-template-columns: repeat(3,1fr); gap: 5px; background: #fff; padding: 10px; box-shadow: 0 0 15px 0px rgba(0,0,0,.1);
  .item{ cursor: pointer;
    img{width: 24px;}
  }
}

.ant-layout-content{padding: 40px 40px; max-width: 100%; margin: auto; width: 100%; 
  // padding-left: 70px; padding-top: 115px;
  // @media only screen and (max-width: 1440px) {&{max-width: 95%;}}
}


div::-webkit-scrollbar {  width: 5px;  height: 10px;  background-color: #f5f5f5;}
div::-webkit-scrollbar-thumb {  background-color: #c1c1c1;}
div::-webkit-scrollbar-thumb:hover {  background-color: #a0a0a0;}
div::-webkit-scrollbar-track {  background-color: #f5f5f5;}


input:focus {  outline: none;}
.flex-end{display: flex; align-items: center; justify-content: flex-end;}

.ant-table-cell{vertical-align: middle;}
.ant-btn-icon-only{min-width: auto;}
.d-flex{display: flex;}
.flex-between{display: flex; justify-content: space-between;}
.flex-center{display: flex; justify-content: center; align-items: center;}
.flex-v-center{display: flex;  align-items: center;}

#group-member-modal{
  .ant-input-group{position: relative;
    .ant-input-group-addon{position: absolute; right: 30px;left: auto; z-index: 9; background: none;
      button{background: none;}
    }    
  }
  .selected-group-items-list{ margin-bottom: 30px;
    h2{font-weight: 600; margin-bottom: 10px;color: #1b262c;}
    .list{ display: flex; flex-direction: column; gap: 5px;
      .item{ display: flex; justify-content: space-between; align-items: center; border: 1px solid #ddd; padding: 2px 5px; border-radius: 10px;
        .meta{ display: flex; flex-direction: column;
          .name{color: #1b262c; font-size: 13px; font-weight: bold;}
          .username{font-size: 10px; color: #96a8b7;}
        }
        .action{}
      }
    }
  }
}
// .ant-modal-mask{z-index: 1050;}
.ant-modal-wrap{z-index: 1002;}


@for $i from 0 through 100 {
  .gap-#{$i}{
    gap: #{$i}px;
  }
  .p-#{$i}{padding: #{$i}px !important;}
  .pt-#{$i}{padding-top: #{$i}px!important;}
  .pb-#{$i}{padding-bottom: #{$i}px!important;}
  .pr-#{$i}{padding-right: #{$i}px!important;}
  .pl-#{$i}{padding-left: #{$i}px!important;}
  .px-#{$i}{padding-left: #{$i}px!important;padding-right: #{$i}px!important;}
  .py-#{$i}{padding-top: #{$i}px!important;padding-bottom: #{$i}px!important;}
  .m-#{$i}{margin: #{$i}px!important;}
  .mt-#{$i}{margin-top: #{$i}px!important;}
  .mb-#{$i}{margin-bottom: #{$i}px!important;}
  .mr-#{$i}{margin-right: #{$i}px!important;}
  .ml-#{$i}{margin-left: #{$i}px!important;}
  .mx-#{$i}{margin-left: #{$i}px!important;margin-right: #{$i}px!important;}
  .my-#{$i}{margin-top: #{$i}px!important;margin-bottom: #{$i}px!important;}
}

.run-as-different{
  .ant-alert-message{ 
    @media only screen and (max-width: 400px) {&{display: flex; justify-content: center; align-items: center; flex-direction: column;}}

  }
}
.ant-dropdown:has(.user-profile-dropdown){z-index: 1001;}
.richtext-wrapper{
  .ql-editor{height: 350px;}
  .ql-toolbar{border-radius: 20px 20px 0 0; background: #f7f7f7;}
  .ql-container{border-radius: 0 0 20px 20px; background: #fff;}
}
@media screen and (max-width: 1100px) {
  .ant-layout-content{
    margin-top: 100px;
  }
  .sidebar-menu{
    // margin-top: -50px;
    padding-top: 0 !important;
    z-index: 99999 !important;
  }
}

