
.mT-5{margin-top: 5px;}
.mT-10{margin-top: 10px;}
.mT-20{margin-top: 20px;}


.mB-20{margin-bottom: 20px;}
.mB-10{margin-bottom: 10px;}
.mB-5{margin-bottom: 5px;}


.center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-padding{
    padding: 0!important;
}