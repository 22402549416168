.content-detail{
    .page-title{color: var(--primary-icon-color); font-size: 20px; font-weight: 700; margin-bottom: 15px;}
    .content-image{ margin:20px 0;
        img{width: 100%; height: 250px; object-fit: cover; display: block;}
    }
    .content{padding: 15px 0;
        p{color: #000;}
        h1{color: #000;}
        h2{color: #000;}
        h3{color: #000;}
        h4{color: #000;}
        h5{color: #000;}
        ol,ul{padding-left: 20px;
            li{color: #000; font-size: 15px;}
        }
        ul{list-style: disc;}
        ol{list-style: auto;}
        blockquote{ border-left: 7px solid var(--primary-impact-color); padding: 15px 10px; background: rgba(213, 143, 10, .1);border-radius: 4px; font-style: italic;}
        img{max-width: 100% !important;}
    }    
}
.faq-container{
    h3{font-size: 18px; color: var(--primary-icon-color); font-weight: 600; margin-bottom: 20px;}
    .ant-collapse{
        .ant-collapse-item{margin-bottom: 10px;
            .ant-collapse-header{padding: 5px 0  !important;  border-radius: 0px !important; display: flex; align-items: center;
                .ant-collapse-expand-icon{}
                .ant-collapse-header-text{font-size: 16px; font-weight: 600; line-height: 1;}
            }
        }
    }
}