.form-definition-form {
    .question-form{
        display: block;

        .row-space-between{
            gap: 8px;
        }

        .column-left{
            flex: 1;

            .ant-form-item-control{
                width: 100%;
            }
        }
    }
}