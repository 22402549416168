.gi-apply-form {

  .header {
    border: none;

 
      .question-number{
        color: #ff3b46;
        color: $primary-color-v2;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 14px;
      }

      .question {
        color: #110f48;
        font-size: 16px;
        margin-bottom: 5px;
      }

      .question-info{
        font-size: 12px;
        font-style: italic;
        color: #96a8b7;
      }


    }
  
    
  

  .body {
    padding: 0;
    margin-top: 34px;

    .comment-item {
      // border: solid 1px #eceef4;

        .comment-label{
            color: $primary-color-v2;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 8px;
            position: relative;
            padding-left: 15px;

            &::before{
                content: ' ';
                position: absolute;
                left: 0;
                top: 2px;
                width: 10px;
                height: 10px;
                background-color: $primary-color-v2;
                border-radius: 10px;
            }
        }


        &.blue{
          .comment-label{
            color: #10a3e9;

            &::before{
              background-color: #10a3e9;
            }
          }
        }
      
    }
  }
}
