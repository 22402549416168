#root,
.App {
  height: 100%;
}
p {
  font-size: 15px;
  line-height: 1.5;
}

.horizontal-list {
  list-style: none;

  li {
    float: left;
  }
}

.site-layout-background {
  min-height: 360px;
  padding: 24px;

  @include MQ(SM) {
    padding: 0;
    padding-bottom: 20px;
  }
}

.logo-mobile {
  margin: auto 10px;

  img {
    height: 40px;
  }
}

.basic-list {
  li {
    line-height: 20px;
    font-size:14px;
    &::before {
      font-size: 25px;
      line-height: 26px;
      vertical-align: middle;
      content: "\2022";
      color: $primary-color-2;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: 0em;
    }
  }
}

.ant-menu-submenu-open.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow {
  color: white !important;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  border-radius: 10px;
}

.ant-tag {
  border-radius: 10px !important;
}

.ant-btn {
  border-radius: 8px;
  min-width: 110px;
  height: 30px;
  border: none;
  color: $title-color;
  font-size: 14px;
  background-color: $btn-default-bg-color;

  @include MQ(SM){
    min-width: auto;
  }

  &.ant-btn-link {
    color: $primary-color-2;
    background: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    min-width: auto;
    padding: 0;
  }

  &.ant-btn-lg {
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
  }

  &.green-outline {
    background-color: transparent;
    border: solid 1px #335121;
    color: #335121;
  }
  
  &.primary-outline {
    background-color: transparent;
    border: solid 1px $primary-color-2;
    color: $primary-color-2;
  }

  &.outline {
    border: solid 1px $body-text-color;
    background-color: transparent;
  }

  &.green {
    background-color: #c2eabd;
    color: #518d49;
  }

  &.orange {
    background-color: $orange-btn-bg-color;
    color: $orange-btn-text-color;
  }

  &.orange-2 {
    background-color: #e8c241;
    color: white;
  }

  &.red {
    background-color: $accent-color;
    color: white;
  }

  &.red-2 {
    background-color: #fdc9cc;
    color: $accent-color;
  }

  &.blue {
    background-color: $blue-btn-bg-color;
    color: $blue-btn-text-color;
  }

  &.purple {
    background-color: #b783ff;
    color: white;
  }

  &.primary {
    background-color: $primary-color-2;
    color: white;
  }
}

.ant-input,
.ant-select-selector,
.ant-upload,
.ant-picker,
.ant-input-number {
  border-radius: 15px !important;
}

.ant-progress-inner {
  padding: 2px;
}

.featured-title {
  font-size: 40px;
  line-height: 50px;
  color: $title-color;
  font-weight: bold;
}
.featured-desc {
  font-size: 16px;
  line-height: 24px;
  color: $title-color;
}
.content-title {
  color: $title-color;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 18px;
  margin-top: 20px;
}
.sub-content-title {
  color: $primary-color-2;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 18px;
  margin-top: 20px;
}
.table-title {
  color: $title-color;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 20px;
}

.title-style-3 {
  color: $title-style-3-color;
  font-size: 14px;
  line-height: 1.2;
  margin: 10px 0;
}

.card-title {
  color: #40a3e9;
  font-size: 20px;
  line-height: 1.2;
  margin: 10px 0;
  font-weight: bold;
}


.table-title {
  color: $title-color;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 20px;
}
.action-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}
.button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  button {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  &.align-right {
    justify-content: flex-end;
    button {
      margin-left: 5px;
    }
  }
}

.ant-modal-content {
  border-radius: 20px !important;
  .ant-modal-header {
    border-radius: 20px !important;
  }
}

.column-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.column-end {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.column-left {
  @extend .column-center;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}

.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
}

.flex-lister {
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  flex-wrap: wrap;
  justify-content: center;
  // justify-content: space-around;

  & > div {
    margin-bottom: 10px;
    margin-right: 20px;
  }
}

.list-card-iinner-item {
  margin-bottom: 5px;
  font-size: 12px;

  button {
    width: 100%;
    margin-top: 13px;
  }
}


.flex-lister-col {
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.ant-alert-banner {
  border-radius: 10px !important;
}

.simple-data-item {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #eceef4;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &:last-child {
    padding-bottom: 0;
    border: none;
    margin: 0;
  }

  .label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #a2aebc;
    margin-bottom: 9;
  }

  .value {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #232931;
  }
}

.calendar-date-card {
  padding: 10px 21px 10px 10px;
  border-radius: 6px;
  background-color: #96a8b7;
  color: white;

  &.has-sep {
    position: relative;
    margin-bottom: 30px;

    &::after {
      content: " ";
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: -15px;
      left: 0;
      background-color: #eceef4;
    }

    &:last-child {
      margin-bottom: 0px;
      &::after {
        display: none;
      }
    }
  }

  &.blue {
    background-color: #10a3e9;
  }

  &.green {
    background-color: #96d18e;
  }

  &.red {
    background-color: #f03f47;
  }
}

.small-action-btn-container {
  &.right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  button {
    margin-right: 5px;
    min-width: auto !important;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ant-tooltip-inner {
  border-radius: 5px !important;
}

.labelled-data {
  padding: 10px 13px;
  border-radius: 6px;
  background-color: #eceef4;
  height: 40px;

  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #23374d;
}

.ant-badge-status-dot {
  width: 16px;
  height: 16px;
}

.outline-data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 20px;
  border-radius: 8px;
  border: solid 1px #eceef4;

  .title {
    font-size: 14px;
    line-height: 1.71;
    text-align: left;
    color: #23374d;
  }

  .data {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    text-align: right;
    color: #b783ff;
  }
}

.transparent {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  line-height: normal;
  display: flex;
}


.ant-table-wrapper{
  overflow-x: auto;
}


.seperator {
  width: 100%;
  height: 0.5px;
  margin: 20px 0;
  background-color: #eceef4;
}

.ant-table-wrapper{
  border-radius: 20px;
}

.ant-result-info .ant-result-icon > .anticon{
  color: $primary-color-v2!important;
}