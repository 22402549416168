.mentorship-rank-modal {
  display: flex;
  flex-direction: column;
  .title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: center;
    color: #23374d;
    margin-bottom: 20px;
  }
}
