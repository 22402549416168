.icon-number-box {
  color: white;
  border-radius: 15px;
  padding: 20px;

  @include MQ(SM){
    margin: 10px 0px;
  }

  &.green {
    background-color: #96d18e;

    .icon{
        color: #96d18e;
    }
  }

  &.blue {
    background-color: #10a3e9;

    .icon{
        color: #10a3e9;
    }
  }

  &.orange {
    background-color: #e79d46;

    .icon{
        color: #e79d46;
    }
  }

  .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .icon {
      width: 50px;
      height: 50px;
      margin: 0 87px 21px 0;
      padding: 10px 10px 10px 11px;
      border-radius: 8px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      font-size: 30px;
      text-align: center;
    }

    .number {
      font-size: 40px;
      font-weight: bold;
      line-height: 1.03;
      text-align: right;
      color: #ffffff;
    }
  }

  .second-row{
    margin-top: 20px;
    
    .title{
        font-size: 16px;
        font-weight: bold;
        line-height: 1.25;
        text-align: left;
        color: #ffffff;
    }
  }
}
