.info-card {
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0 8px 20px 0 rgba(35, 55, 77, 0.2);
  }

  .title {
    margin-top: 35px;
    font-size: 20px;
    line-height: 24px;
    // color: $primary-color-2;
    color: #23374d;
    font-weight: bold;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
  }
}
