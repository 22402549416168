.carousel-container{
    
    .slick-dots{
        //background-color: #ddd;
        position: absolute;
        border-radius: 50px;
        padding: 10px;
        margin: 0 !important;
        left: 25px !important;
        right: auto !important;
        text-align: left;
        display: flex;
        align-items: center;
        bottom: 20px;
        width: 100%;

        @include MQ(SM){
            bottom: -40px;
            display: flex;
            justify-content: center;
            left: 0!important;
            right: 0!important;
            margin: auto!important;
        }

        ul{
            li{float: left; margin-left: 10px;}
            
        }
        

        button{
            cursor: pointer;
            outline: none;
            border: none;
            border-radius: 20px!important;
            background-color: $primary-color-light !important;
            color: $primary-color-light !important;
            transition: all 0.2s;
            display: block;
            &::before{
                color: transparent; 
             }
        }

        .slick-active{
            button{
                background-color: $primary-color-2 !important;
                color: $primary-color-2 !important;
                width: 25px;

                &::before{
                   color: transparent; 
                }
            }
        }

        .all-items{
            cursor: pointer;
            height: 15px;
            border-left: $body-text-color solid 1px;
            font-size: 14px;
            padding-left: 20px;
            margin-left: 20px;
            line-height: 15px;
            color: $body-text-color;
        }
    }

    .carousel-item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include MQ(SM){
            flex-direction: column-reverse;
        }
        
        .text{
            // flex: 2;
            padding: 50px 40px;
            
            .title{
                color:$title-color; 
                font-size: 38px;
                line-height: 1.1; 
                font-weight: 700;

                @include MQ(SM){
                    font-size: 20px;
                }
            }
            .description{
                font-size: 15px;
                line-height: 1.5; 
                margin-top: 15px; 
                color: $body-text-color;
            
                @include MQ(SM){
                    font-size: 12px;
                }
            }

           

            @include MQ(SM){
                text-align: center;
                padding: 10px;
            }
        }

        .image{
            flex: 1;
            display: flex;
            justify-content: flex-end;
            padding: 10px;
            max-height: 300px;

            img{
                border-radius: 15px;
                width: 100%;
                @include MQ(SM){
                    height: 265px;
                }
                
            }
        }
    }
}