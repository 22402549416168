.simple-link-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    height: 284px;
    // margin: 146.5px 20px 146px 175px;
    padding: 50px 20px;
    border-radius: 20px;
    box-shadow: 0 6px 10px 0 rgba(35, 55, 77, 0.1);
    background-color: #ffffff;
    cursor: pointer;
    transition: all .2s;

    &:hover{
        box-shadow: 0 16px 20px 0 rgba(35, 55, 77, 0.1);
        transform: scale(1.05);
    }

    .title{
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #23374d;
    }

    .icon{
        font-size: 50px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.26;
        letter-spacing: normal;
        text-align: center;
        color: $primary-color-2;
    }
  }
  