$blue-color: #40a3e9;
$orange-color: #e79d46;
$green-color: #c2eabd;
$green-dark-color: #71a76a;

.number-item{
    padding: 6px;
    height: 40px;
    display: flex;
    flex-direction: row;
    background-color: gray;
    color: white;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;

    .icon{
        font-size: 16px;
        width: 16px;
        margin-left: 15px;
        flex: 1;
    }

    .title{
        flex: 7;
        text-align: left;
        font-size: 13.5px;
    }

    .number{
        flex: 2;
        width: 52px;
        height: 32px;
        font-size: 18px;
        font-weight: bold;
        background-color: white;
        text-align: center;
        line-height: 32px;
        border-radius: 5px;
    }

    &.blue{
        background-color: $blue-color;
        .number{
            color: $blue-color;
        }
    }
    
    &.orange{
        background-color: $orange-color;
        .number{
            color:$orange-color;
        }
    }
    
    &.green{
        background-color: $green-color;
        color: $green-dark-color;
    }
}