.most-mentor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 60px;

  &:last-child{
      margin-bottom: 0;
      &::after {display: none;}
  }

  &::after {
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    bottom: -30px;
    border-radius: 8px;
    border: solid 1px #eceef4;
  }

  .image {
    flex: 2;

    img{
      height: 66px;
    }
  }
  .texts {
    flex: 4;
    text-align: left;
    .subtitle {
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #96a8b7;
    }

    .title {
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #23374d;
    }
  }

  .number {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #eceef4;
    border-radius: 50px;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #96a8b7;
  }
}
