.horizontal-lister{
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: solid 1px $sep-color;
    padding: 9px;

    @include MQ(SM){
        flex-direction: column;
        height: auto;
        justify-content: flex-start;
    align-items: flex-start;
    }
    
    .title{
        font-weight: 800;
        color: $title-color;
        font-size: 14px;
    }
    .list{
        display: flex;
        flex: 5;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        @include MQ(SM){
            margin-top: 10px;
        }
       
        .item, .labelled-data{
            cursor: pointer;
            // width: 40px;

            @include MQ(SM){
                margin-right: 5px;

                &:last-child{
                    margin-right: 0;
                }
            }
        }

        

        .more{
            background-color: $btn-default-bg-color;
            border-radius: 30px;
            height: 40p;
            line-height: 40px;
            text-align: center;
        }
    }
}