
$main-button-size: 80px;
$main-button-mobile-size: 60px;

.app{
    position: fixed;
    bottom: 0;
    z-index: 999;
    
    .app-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      position: fixed;
      bottom: 40px;
      left: 0px;
      transition: opacity 0.8s;
  
      .short-title {
        margin-top: 3px;
        font-size: 10px;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        /* position: absolute; */
        /* bottom: -27px; */
        /* background-color: #f8750b; */
        color: #fff;
        /* border-radius: 5px; */
        /* width: 90px; */
        padding: 3px;
        text-align: center;
      }
  
      button {
        border-radius: 5px;
        flex-direction: column;
        border: none;
        cursor: pointer;
        outline: none;
        width: 10px;
        height: 100px;
        padding: 5px 15px;
        background-color: #f8750b;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
  
        // @include MQ(SM){
        //   width:$main-button-mobile-size;
        //   height: $main-button-mobile-size;
        //   padding: 0;
        // }
  
        .icon {
          font-size: 18px;
        }
      }
    }

    .app-panel{
      z-index: 3;
      background: #fff;
      position: absolute;
      width: 300px;
      height: 0px;
      border-radius: 10px;
      bottom: 125px;
      left: 20px;
      transition: opacity .2s, height .2s, visibility .2s;
      box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.5);
      overflow: hidden;

      .close{
        position: absolute;
        cursor: pointer;
        padding: 3px;
        background-color: #bc001b;
        color: #fff;
        width: 21px !important;
        height: 21px !important;
        right: 5px !important;
        top: 5px !important;
        border: #c93d3d solid 2px;
        border-radius: 50px;

        &:before {
          background-color: #fff; 
          transform: rotate(45deg);
          position: absolute;
          content: '';
          height: 2px;
          width: 100%;
          top: 50%;
          left: 0;
          margin-top: -1px;
          background: #fff;
          border-radius: 100%;
          transition: background .2s;
        }
        &::after {
          transform: rotate(-45deg);
          background-color: #c93d3d;
          position: absolute;
          content: '';
          height: 2px;
          width: 100%;
          top: 50%;
          left: 0;
          margin-top: -1px;
          background: #fff;
          border-radius: 100%;
          transition: background .2s;
        }
      }

      .app-panel-body{
        padding: 20px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .title{
          color: #f8750b;
          font-size: 16px;
          text-align: center;
        }

        .panel-content{
            margin-top:20px
        }
      }
    }
  }