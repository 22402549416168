@mixin appylyShadow($color){
    box-shadow: 4px 4px 4px $color;
}

.target-card{

    &.notStarted{
       @include appylyShadow($primary-color-2);
    }
    
    &.inprogress{
        @include appylyShadow(#e79d46);
    }
    
    &.paused{
        @include appylyShadow($accent-color);
    }
   
    &.canceled{
        @include appylyShadow($accent-color);
    }
   
    &.completed{
        @include appylyShadow($menu-color-1);
    }
}