

.step-container {
  margin: 0 auto;
  width: 70%;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  .ant-steps-item-icon{
    background: #f5f6fa!important;
  }

  .first-step{
    
    .ant-steps-item-title{
      color: #7aba3e!important;

       &:after{
            background-color: #c0d7ea!important;
        }
    }
 

    .ant-steps-icon{
      color:#7aba3e!important;
    }

    .ant-steps-item-icon{
      border-color:#7aba3e!important;
    }
  }
 
}
.rankcard-container {
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  height: 235px;
  background-color: #ffff;
  border-radius: 20px;
  
  @media only screen and (max-width: 992px) {
    width: 100%;
    height:250px;
  }

  .rankcard-qnumber {
    color: #7aba3e;
    font-size: 16px;
    font-weight: bold;
    padding: 19px 0px 0px 30px;

    .rankcard-info{
      width: 70px;
      height: 30px;
      float: right;
      margin-right: 30px;
      padding: 5px 9px 6px;
      border-radius: 6px;
      border: solid 1px #96a8b7;

      @media only screen and (max-width: 992px){
        margin-top: -8px;
      }

      .info-desc{
        font-size: 14px;
        color: #96a8b7;
        padding-left: 6px;
        font-weight: normal;
      }
    }
  }

 

  .rankcard-question {
    color: #110f48;
    font-size: 16px;
    font-weight: bold;
    padding: 16px 0px 0px 30px;
  }
  .rankcard-qdescription {
    font-size: 12px;
    color: #96a8b7;
    padding: 6px 0px 0px 30px;
  }
  .rankcard-comment {
    padding: 20px 30px 0px 30px;
    width: 100%;
    height: 100px;
  }
}

.rankcard-footer {
  display: flex;
  flex-direction: row;
 align-items: center;
  margin-top: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  box-shadow: 0 -1px 10px 0 rgba(35, 55, 77, 0.1);
  background-color: #ffff;

  .rankform-share-button {
    margin:auto;
    margin-left:0px;
    margin-right: 350px;
    width: 160px;
    height: 40px;
    border-radius: 8px;
    background-color: #7aba3e;
  
    @media only screen and (max-width: 992px){
     margin-right: 0px;
    }
   
  }

  .rankform-save-button {
    margin:auto;
    margin-right: 20px;
    background-color: #fff;
    width: 90px;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #7aba3e;

    @media only screen and (max-width: 992px) {
      margin-left:-70px;
    }
    
  }

  .rankform-delete-button {
    margin-left: 30px;
    margin-right: 100px;
    width: 90px;
    height: 40px;

    border-radius: 8px;
    border: solid 1px #96a8b7;
  }
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #c0d7ea !important;
}