.ideaSearchDropDownDiv{
    padding: 8px;
    min-width: 350px !important;
    display: flex;
    flex-direction: row-reverse;
    .ant-input-group-addon{
        left: 3px !important
    }
    background-color: var(--hidden-popup-background-color);
    border: 1px solid white;
    border-radius: 5px;
}

.ideaSearchInput{
    gap: 10px;
    width: 350;
    margin-bottom: 8;
    display: block;
}

.ideaTypeSpace{
    .ant-space-item:first-of-type{
        display: flex;
    }
}

.ideaTypeNameColumnHide{
    display: none;
}