
    .notification-list-item{
        .icon{
            margin-right: 5px;
        }
        .date{
            display: block;
            font-size: 11px;
            color: $body-text-color;
        }
    }
