.intranet-content-card{
    width: 100%;
    //box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.15);
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.15);;
    border-radius: 10px;
    .intranet-content-card-header{ display: flex; align-items: center; justify-content: space-between; height: 60px; padding: 0px 20px;
        .title{ display: flex; align-items: center; gap: 15px;
            .icon{
                img{width: 50px; height: 50px; object-fit: contain;}
            }
            h2{font-size: 14px; font-weight: 600; color: #000; line-height: 19px;}
        }
        .action{ display: flex; gap: 10px;
            a{color: var(--primary-impact-color); font-size: 11px;
                &.cam-icon{ font-size: 15px; display: flex; width: 32px; height: 32px; border-radius: 100%; border: 1px solid #c4c4c4; justify-content: center; align-items: center; position: relative; color:var(--primary-impact-color) ;
                    span{ position: absolute; right: -5px; top:-5px; background: var(--primary-impact-color); border-radius: 100%; width: 15px; height: 15px; display: flex; justify-content: center; align-items: center; font-size: 11px; font-weight: 900; color: #fff;}
                }
            }
        }           
        &.idea-header{
            @media only screen and (max-width: 460px) {
                display: flex;
                flex-direction: column !important;
                height: 90px;
                .title{
                    width: 100% !important;
                    padding-left: 0 !important;
                    padding-top: 5px !important;
                }
                .idea-header-btn{
                    display: flex;
                    justify-content: center;
                    width: 100% !important;
                    padding-left: 0 !important;
                    padding-top: 5px !important;
                    padding-bottom: 5px !important;
                }
            }
        }  
    } 

    .idea-board-footer{
        height: 30px !important;
        display: flex;
        flex-direction: row-reverse;
        padding-top: 5px;
        padding-right: 30px;
        a{
          color: var(--primary-impact-color);
        }
        @media only screen and (max-width: 768px) {
            height: 40px !important;
            padding-top: 10px;            
         }
      } 

    .intranet-content-card-body{height: 300px; overflow: auto; padding-left: 20px;
        .ant-tabs-card{
            height: 100%;
        }
        // @media only screen and (max-width: 768px) {&{height: auto; }}
        .ant-list-items{
            // height: 290px;
            max-height: 290px;
            @media only screen and (max-width: 768px) {&{   height: auto; }}

        }
        .ant-list-item{
            &.cursor-pointer{cursor: pointer;}
            .ant-list-item-meta{ gap: 12px; align-items: center;
                .ant-list-item-meta-avatar{ margin-right: 0;
                    .event-time-avatar{width: 50px; height: 50px; display: flex; flex-direction: column; justify-content: center; align-items: center; border-radius: 100%; background: #bfd9f8; color: var(--primary-impact-color); font-size: 11px; font-weight: 800;
                        span{line-height: 14px;}
                    }
                    .ant-avatar-image{width: 40px; height: 40px;}
                }
                .ant-list-item-meta-content{
                    padding-right: 5px;
                    .ant-list-item-meta-title{color: #1f1f1f; font-size: 13px; font-weight: 600; line-height: 17px;}
                    .ant-list-item-meta-description{color: rgba(31, 31, 31,.63);line-height: 17px;
                        .ant-btn{color: var(--primary-impact-color); font-size: 11px; border: 1px solid var(--primary-impact-color); background: none; height: 21px; padding: 0; width: 110px;}
                    }
                }
            }
            .arrow{padding: 0 10px;}
        }
        &.activities{
            height: 225px;   
            .ant-tabs-tabpane-active{
                max-height: 180px;
            }
        } 
        &.idea-board{
            height: 270px !important;
            .idea-date{
                padding-left: 0px !important;
            }
            .idea-category{
                display: flex;
                gap: 3px;
                align-items: center;
                flex-wrap: wrap;
                justify-content: flex-end;
            }
        }  
        &.born-this-week-body{
            @media only screen and (max-width: 400px) {
                padding-left: 5px;
            }
            .ant-tabs:first-of-type{
                @media only screen and (max-width: 400px) {
                    display: flex;
                    flex-direction: column;
                }
            }
            @media only screen and (max-width: 400px) {
                .ant-tabs-nav-list{                
                    display: flex;
                    flex-direction: row !important;
                    flex-wrap: wrap;
                    .ant-tabs-tab{
                        padding: 0 !important;
                        gap : 0;
                    }
                }
            }
        } 
        &.meal-menu-body{
            @media only screen and (max-width: 400px) {
            padding-left: 0px;
            
            .ant-tabs:first-of-type{
                    display: flex;
                    flex-direction: column;
            }
            .ant-tabs-tabpane{
                    padding-left: 0;
            }
            .ant-spin-container{
                    padding-left: 10px;
            }
            .ant-tabs-nav-list{                
                display: flex;
                flex-direction: row !important;
                flex-wrap: wrap;
                .ant-tabs-tab{
                    padding: 0 !important;
                    gap : 0;
                }
            }
        }
        }
    }


    .ant-tabs-nav-list{ background: #fff !important;
        .ant-tabs-tab{ border:none !important; margin: 0 !important;background: none !important; border-radius: 12px !important;
            .ant-tabs-tab-btn{color: #c4c4c4 !important; background: none !important; font-size: 12px !important;}
            &.ant-tabs-tab-active{background: transparent !important; 
                .ant-tabs-tab-btn{
                    color: var(--primary-color) !important;
                    border-bottom: 2px solid var(--primary-impact-color);
                }
            }
        }
    }
    .meal-list-inline-card{
        .ant-tabs-nav{margin-bottom: 5px;}
        .ant-tabs-nav-list{
            .ant-tabs-tab{padding: 0 !important; 
                .ant-tabs-tab-btn{display: flex; align-items: center; flex-direction: column; font-weight: 400; color: #c4c4c4 !important;}
                &.ant-tabs-tab-active{background: none !important; 
                    .ant-tabs-tab-btn{color: var(--primary-impact-color) !important;}
                }
            }
            img{width: 35px; height: 35px;}
        }
        .ant-list-items{height: 225px;
            .ant-list-item{
                .ant-list-item-meta{ 
                    .ant-list-item-meta-description{
                        .details{display: flex; justify-content: space-between; align-items: center;
                            .calorie{font-style: italic; flex: 1;}
                            .allergen{ margin-right: 15px; flex: 3;
                                ul{ display: flex; gap: 3px; align-items: center; flex-wrap: wrap; justify-content: flex-end;
                                    li{
                                        img{width: 25px; height: 25px; cursor: pointer;}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

}
.cursor-pointer{cursor: pointer;}
.calendar-toolbar-container{
    .calendar-toolbar{display: flex; justify-content: space-between; align-items: center;padding: 10px 0;
        .center{font-weight: 700;}
    }
}


 .ant-tabs-nav-list .ant-tabs-tab{
    padding: 4px 16px !important;
 }

 