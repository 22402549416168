.myTasks{
    position: relative;
    #btnTaskApprove{
        color: green;
    }
    #btnTaskApprove:hover{
        background-color: green;
        border-color: green;
        color: white;
    }
    #btnTaskReject{
        color:red
    }
    #btnTaskReject:hover{
        background-color: red;
        border-color: red;
        color: white;
    }
}
.taskDetailModal{
    .request-buttons{
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        padding: 10px;
        #btnApprove{
            color: green;
        }
        #btnApprove:hover{
            background-color: green;
            border-color: green;
            color: white;
        }
        #btnReject{
            color:red
        }
        #btnReject:hover{
            background-color: red;
            border-color: red;
            color: white;
        }
    }

    .ant-collapse{
        .ant-collapse-item{            
            .ant-collapse-header{
                padding-left: 0 !important;
                display: flex;
                align-items: center;
            }
        }
    }
    .ant-collapse-expand-icon{
        color: $primary-color !important;
    }
    .ant-collapse-header-text{
            font-size: 13px !important;
            color: $primary-color !important;
    }
    .ant-collapse-content-box{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}