.meal-menu-management{
    .ant-picker-calendar-header{ padding: 20px;
        .ant-picker-calendar-mode-switch{display: none;}
    }
    .rbc-event,.rbc-selected{background: none !important;
        &:focus{outline: none !important;}
    }
    .rbc-day-bg,.rbc-row{cursor: pointer;}
    .meal-menus{ display: flex; flex-direction: column ; gap: 5px;
        span { display: flex;  position: relative;padding-left:10px; color: rgba(0, 0, 0, 0.85);
            &::before{content: ""; width: 8px; height: 8px;  border-radius: 100%; display: block; position: absolute;left: 0; top: 3px;}
            &.main{
                &::before{background: #f09286}
            }
            &.salads{
                &::before{background:#289e51}
            }
            &.desserts{
                &::before{background:#565f73}
            }
        }
    }
}