.chat {

  .message-area {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 30px;

    .chat-bubble {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      margin-bottom: 40px;

      &:last-child{
        margin-bottom: 0px;
      }
      

      .user {
        text-align: left;
        color: $primary-color-2;
        margin-bottom: 6px;
      }

      .message {
        padding: 9px 26px 9px 10px;
        background-color: #eceef4;
        color: #23374d;
        border-radius: 10px;
        width: 300px;

        @include MQ(SM){
          width: 100%;
        }

        .message-text{
            font-size: 14px;
            margin-bottom: 4px;
            font-weight: bold;
        }

        .extra {
          font-size: 10px;
          margin-bottom: 7px;
        }

        .date {
          font-size: 10px;
        }
      }

      &.current {
          align-items: flex-end;
        .user {
          text-align: right;
        }
        .message {
          background-color: $primary-color-2;
          color: white;

          
        }
      }

      &.has-error {
        .message {
            background-color: white;
            border: solid 1px $accent-color;
            color: $accent-color;
        }

        .error-extra{
            color: $accent-color;
            margin-top: 10px;
        }


      }
    }
  }
}
