.surveyForm .ant-form-item-label label{
    font-weight: bold;
 }

 .surveyForm{
    .disabledQuestion{
        margin-bottom: 0px !important;
    }
   img{
      width: 100% !important;
   }
   .ant-form-item-control{   
      width: 100% !important;
      max-width: 100% !important;
   }
 }

 .survey-modal{
   @media only screen and (max-width: 768px) {&{max-width: calc(100vw - 100px);}}
   @media only screen and (max-width: 640px) {&{max-width: calc(100vw - 40px);}}
   .modalInfoMessage{
      @media screen and (max-width: 500px){
          padding-top: 12px;
      }
  }  
  .newIdeaTypes {    
      .ant-select-selector{
      @media screen and (max-width: 500px){
          align-items: start;
      }
      @media screen and (max-width: 447px){
          height: 65px !important;
      }
      }
      .ant-select-selection-overflow-item{
      @media screen and (max-width: 500px){
          padding: 0;
      }
      .ant-select-selection-item{
          @media screen and (max-width: 500px){
          line-height: 20px;
          height: 20px;
          }
      }
      }
  }

   .ant-select-selection-item,.ant-select-selection-overflow{height: 40px;}
   .newIdeaTypes,.ant-select-selector{height: 55px !important}
   .ant-modal-footer{display: none;}
   .ant-modal-close{position: absolute; background: #c45041; width: 33px; height: 33px; top:-15px ; right:-15px; border-radius: 100%; display: flex; justify-content: center; align-items: center;color: #fff;
       &:hover{color:#fff}
   }
   .ant-modal-content{border-radius: 4px !important;}
   .ant-modal-header{width: max-content; border-radius: 4px 4px 0 0 !important; top: -50px; position: absolute; border:none; padding:15px;
       .ant-modal-title{color: #4f5769; font-size: 14px; font-weight: 700;}
   } 
   
}
 