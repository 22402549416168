.file-management{padding: 20px; background-color: #fafafa; border: 1px solid #d9d9d9; border-radius: 25px;
    .file-management-toolbar{margin-bottom: 15px; display: flex; justify-content: space-between; align-items: center;  border-bottom: 1px solid #d9d9d9; padding-bottom: 20px;
        div{display:flex; gap: 10px; align-items: center;
            &.right{justify-content: flex-end;}
        }
    }
    .file-list{ display: grid;  gap: 10px; grid-template-columns: repeat(5,1fr);
        @media only screen and (max-width: 1366px) {&{grid-template-columns: repeat(3,1fr) }}
        @media only screen and (max-width: 680px) {&{grid-template-columns: repeat(2,1fr) }}
        @media only screen and (max-width: 480px) {&{grid-template-columns: repeat(1,1fr) }}
        .item{ display: flex;  gap: 5px;   cursor: pointer; flex-direction: column; border: 1px solid #efefef; padding: 5px;
            &:hover{background: rgba(222, 247, 255 , .5);}
            .meta{ display: flex; align-items: center; gap: 5px;
                .name{  display: flex; flex-direction: column; gap: 3px;
                    .file-name{text-overflow: ellipsis; white-space: nowrap; overflow: hidden; font-size: 14px; font-weight: 500; width: 130px; }
                    .other-info{font-size: 10px; color: #6f6f6f;  }
                
                }
                .icon{ font-size: 40px;}
                
            }
            .action{
                a{font-size: 20px;}
            }
            &.folder{
                .meta{
                    .icon{color:#ffd769}
                }
            }
            &.file{
                .meta{
                    .icon{}
                }
            }
        }
    }
} 