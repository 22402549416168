.ant-steps {
    .ant-steps-item-finish .ant-steps-item-icon{
        background-color: #7aba3e!important;
        border-color: #7aba3e!important;
    }
  .ant-steps-item-active .ant-steps-item-title {
    color: #7aba3e !important;
    font-weight: bold;
  }
  .ant-steps-item-icon {
    background: #f5f6fa !important;
  }

  .ant-steps-item-title {
    color: #7aba3e !important;

    &:after {
      background-color: #7aba3e !important;
    }
  }

  .ant-steps-icon {
    color: #7aba3e !important;
  }

  .ant-steps-item-icon {
    border-color: #7aba3e !important;
  }

  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #7aba3e !important;
}
}
