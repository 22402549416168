.ant-steps-item-icon, .ant-steps-item-content{
    vertical-align: middle!important;
    font-weight: bold;
  }
  .ant-steps-item-icon {
    width: 50px!important;
    height: 50px!important;
    line-height: 50px!important;
  }
  
  .ant-steps-item-active{
    .ant-steps-item-title{
      font-weight: bold!important;
      color: $primary-color-2!important;
    }
  }
  
  .ant-steps-item-finish{
    .ant-steps-item-title{
      font-weight: bold!important;
      color: $success-color!important;
    }


    .ant-steps-item-icon{
        background-color: $success-color!important;
        border-color: $success-color!important;
        .ant-steps-icon{
            color: white!important;
        }
    }
}
