.ant-tabs{
  overflow: inherit!important;
}
.ant-tabs-tabpane{
  overflow: auto;
}

.ant-tabs-nav-list {
  flex: 1;
  justify-content: space-around;

  .ant-tabs-ink-bar {
    background: #10a3e9 !important;
  }

  .ant-tabs-tab {
    padding: 12px 15px;
  }

  .ant-tabs-tab-btn {
    font-size: 16px;
    font-weight: bold;
  }
}