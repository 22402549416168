.sidebar-menu{ width: 55px; background: transparent;display: block; top: 0; height: 100vh; z-index: 10; 
    transition: left .3s; 
    position: fixed; 
    transition:width .3s linear;
    -webkit-transform:translateZ(0) scale(1,1);
    padding-top: 69px;
    .menu{ display: flex; flex-direction: column; justify-content: space-between; height: 100%;
      border-radius: 10px;

        .menu-header{padding: 8px; display: flex;  height: 63px;
            .close-btn{color: #636877;  font-size: 11px;
                button{display: flex;}
            }
        }
        .menu-items{ height: 100%; overflow-x: auto; overflow-y: scroll; /*display: flex; */ justify-content: center; flex-direction: column; padding-bottom: 60px;
          scrollbar-width: 0px;
          border-radius: 10px;
            background-color: #fafafa;
            a{color: #666665; display: flex; font-size: 16px;  padding:15px 15px; align-items: center; transition: all 0.3s; font-weight: 500; gap: 20px; width: 255px;
                &:hover, &.active{color: var(--primary-impact-color);}
                .menu-item-icon{
                  //backgroundColor:"white", border:"1px solid", borderRadius:"7px", borderColor:"#c4c4c4", padding:"5px" 
                  background-color: #ffffff;
                  border: 1px solid;
                  border-radius: 7px;
                  border-color: #c4c4c4;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 30px;
                  height: 30px;
                  transform: skewX(180deg);
                  i{
                    color: var(--primary-icon-color);
                  }
                }
            }
        }
    }
    &.open{left: 0; z-index: 99;} 
    .menu-item-name{
      font-size: 0px;
      transition:font-size .3s linear;

      // opacity: 0;
      // transition:opacity .4s linear;
    }
    i{
      transform: rotateZ(0deg);
      transition:transform .3s linear;
    }
}
.menu-items::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge ve diğer Webkit tabanlı tarayıcılarda scrollbar'ı gizler */
}

.menu-items {
  scrollbar-width: none; /* Firefox için scrollbar'ı gizler */
}
.sidebar-menu:hover{
    width:255px;
    .menu{
      animation: shadowBoxAnim .25s forwards; 
    }
    i{
      // transform: rotateZ(360deg);
    }
    .menu-item-icon {
      transform: skewX(0deg);
      transition: transform 0.5s ease-in-out;
    }
    .menu-item-name{
      // opacity: 1;
      font-size: 16px;
    }
    .surveyMenu{
      .info{
        right:212px ;
      }
    }
}


@keyframes menuItemsAnim {
  50% {display: block;}
}

@keyframes shadowBoxAnim {
  100% {box-shadow: 5px 3px 5px rgba(0, 0, 0, 0.1);}
  // 100% {box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.2), 16px 0 20px 0 rgba(0, 0, 0, 0.19);}
}

@keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes engorgio {
    0% {
      font-weight: normal;
    }
    50% {
      font-weight: bold;
    }
    100% {
      font-weight: normal;
    }
  }

  @keyframes boxShadowRotate {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 5px 3px rgba(0, 0, 0, 0.19);
    }
    50% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 10px 6px rgba(0, 0, 0, 0.19);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 5px 3px rgba(0, 0, 0, 0.19);
    }
  }
  
  .newMenuItem {
    // font-size: 16px; 
    // animation: pulse 1s infinite; /* 1 saniyede bir tekrarlanacak */
  }

  .surveyMenuContainer{
    position: relative;
  }
  .surveyMenu{
    .info{
      position: absolute;
      top:6px;
      right: 12px;
      color: red;
      transition:right .3s linear;
      font-size: 18px;
      // animation: engorgio 1s infinite; /* 1 saniyede bir tekrarlanacak */

    }
    .menu-item-icon{
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 5px 3px rgba(0, 0, 0, 0.19);
      animation: boxShadowRotate 1s infinite; /* 1 saniyede bir tekrarlanacak */
    }
  }
  
  
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

  
@keyframes fadeOut {
  100% { opacity: 0; }
}

@-moz-keyframes fadeOut {
  100% { opacity: 0; }
}

@-webkit-keyframes fadeOut {
  100% { opacity: 0; }
}

@-o-keyframes fadeOut {
  100% { opacity: 0; }
}

@-ms-keyframes fadeOut {
  100% { opacity: 0; }
}