#imza {
    font-size: 13px;
  
    table {
      line-height: 1.4;
      font-family: "Segoe UI";
      font-size: 10pt;
      width: 100%;
    }
  
    tbody {
      display: flex;
      flex-direction: column;
  
      tr{
        img{
           width: 100%;
        }
      }     
    }
  }
  