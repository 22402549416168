.adminMultiLangLabel{
    background: transparent !important;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    span{
        color: red;
        padding-left: 5px;
    }
    img{
        cursor: pointer;
        width: 24px !important;
    }
    .selectedLangImg{
        width: 28px !important;        
        border: 1px solid rgba($color: #000000, $alpha: .5);
        border-radius: 7px;
    }
}

.adminMultiLangHeader{
    display: flex;
    justify-content: end;
    align-items: end;
    span{           
        padding-left: 5px;
    }
    img{
        cursor: pointer;
        width: 32px;
    }
    .selectedLangImg{
        width: 36px !important;        
        border: 1px solid rgba($color: #000000, $alpha: .5);
        border-radius: 7px;
    }
}