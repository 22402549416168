.user-target-activity {
 
    .name-title {
        display: flex;
        flex-direction: column;

        .name {
            font-size: 16px;
            font-weight: bold;
            color: #232931;
        }

        .title {
            color: #96a8b7;
            font-size: 10px;
        }
    }

    .cell-container{
        display: flex;
        flex-direction: row;
        gap: 20px;

        margin-right: 10px;
    }


    .ant-collapse-item{

        border: solid 1px #ff3b46!important;
        margin-bottom: 10px;

        .ant-collapse-header{
            justify-content: space-between;
            display: flex;
            flex-direction: row;
            align-items: center;

            &::before, &::after{display: none!important;}
        }
    }
    
}
