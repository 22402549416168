.new-demand-modal{
    display: flex;
    flex-direction: row;
    padding: 10px 0;

    @include MQ(SM){
       flex-direction: column;
    }


    @include MQ(SM){
        .right-content, .left-content{
            flex: 1!important;
        }
    }

    .right-content{
        flex: 2;
    }

    .left-content{
        flex: 3;
        margin-right: 60px;
        position: relative;

        @include MQ(SM){
            margin-right: 0px;
        }

        &::after{
            position: absolute;
            content: " ";
            width: 1px;
            height: 100%;
            top: 0px;
            right: -30px;
            border-radius: 8px;
            border: solid 1px #eceef4;
        }

        .form-title{
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.5;
            letter-spacing: normal;
            text-align: left;
            color: #23374d;
        }
    }
}