.input-with-button {
  display: flex;
  flex-direction: row;
  padding: 5px 5px 5px 20px;
  border-radius: 10px;
  border: solid 1px #eceef4;

  input {
    flex: 8;
    border: none;

    &:focus{
        border: none;
        -webkit-box-shadow:none;
    }
  }
  button {
    flex: 1;
  }
}
