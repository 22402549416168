.corporate-templates{
    .ant-col{
        padding-bottom: 20px;
    }
    .doc-list{
        .header-info{
            display: flex;
            align-items: center;
            cursor: pointer;
            h2{
                padding-left: 10px;
                font-weight: bold;
            }
        }
        .ant-card-cover{
            .cover-image{
                position: absolute;
                height: 200px;
            }
            .cover-image:hover{
                opacity: 0.2;
                transition: 0.5s;
            }
        }
    }
}

.ant-card-cover{
    margin-top: 0 !important;
    margin-left: 0 !important;
}