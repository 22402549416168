:root {
  //Template orijinal color : --primary-color: #8f9091;/* Primary rengi burada tanımlayın */
  //Template orijinal icon color --primary-icon-color: #39b3f5;/* Primary rengi burada tanımlayın */

  --primary-color-light: #8f9091;/* Primary rengi burada tanımlayın */
  --primary-color-dark: #ffffff;/* Primary rengi burada tanımlayın */
  --secondary-color-light: #8f9091;/* Primary rengi burada tanımlayın */
  --secondary-color-dark: #ccc;/* Primary rengi burada tanımlayın */
  --primary-icon-color: #39b3f5;/* Primary rengi burada tanımlayın */
  --primary-background-color-dark : rgb(21,32,43);
  --primary-background-color-light : #fff;
  --primary-impact-color-dark :#65c1f7; //#33b592;
  --primary-impact-color-light : #e74c32;
  --primary-demand-color: rgb(240 244 247);
  --primary-demand-color-light: rgb(240 244 247);
  --primary-demand-color-dark : #577b94;
  --secondary-demand-color-dark : #a0cae5;
  --primary-hover-color-dark : rgb(86, 140, 184);
  
  --hidden-popup-bakcground-color :  #fff;
  --hidden-popup-color : black;
  --hidden-popup-secondary-color : rgb(67, 65, 65);

  --primary-divider-color : #f0f0f0;
  --primary-divider-color-light : #f0f0f0;
  --primary-divider-color-dark : #6e6e6e96;

}

@import "./util/reset.scss";
@import "./util/mixin.scss";
@import "./fontawesome/fontawesome.scss";
@import "./slick/slick.min.css";
@import "./var/colors.scss";
@import "./util/helpers.scss";
@import "./components/common.scss";
@import "./components/header.scss";
@import "./components/sider.scss";
@import "./components/menu.scss";
@import "./components/profile_card.scss";
@import "./components/custom_card.scss";
@import "./components/info_card.scss";
@import "./components/page_header.scss";
@import "./components/breadcrump.scss";
@import "./components/content_card.scss";
@import "./components/number_item.scss";
@import "./components/profile_container.scss";
@import "./components/consultant_container.scss";
@import "./components/carousel_container.scss";
@import "./components/category_box_item.scss";
@import "./components/education_category_container.scss";
@import "./components/intro.scss";
@import "./components/logos.scss";
@import "./components/footer.scss";
@import "./components/radio_selection_item.scss";
@import "./components/steps.scss";
@import "./components/page_base.scss";
@import "./components/colored_dash_box.scss";
@import "./components/calendar-summary.scss";
@import "./components/link-box.scss";
@import "./components/most-mentor.scss";
@import "./components/new-demand-modal.scss";
@import "./components/mentorship_process_list_item.scss";
@import "./components/mentorship_process_detail.scss";
@import "./components/chat.scss";
@import "./components/input_with_button.scss";
@import "./components/mentorship_rank_modal.scss";
@import "./components/circle_name.scss";
@import "./components/cell.scss";
@import "./components/icon_number_box.scss";
@import "./components/horizontal_lister.scss";
@import "./components/rate_with_emoji.scss";
@import "./components/notification_list.scss";
@import "./components/target_card.scss";
@import "./components/feedback.scss";
@import "./components/small-app.scss";
@import "./components/tabs.scss";
@import "./components/collapse.scss";
@import "./components/custom_menu.scss";
@import "./components/user_target_activity.scss";
@import "./components/gi_criteria_table.scss";
@import "./components/rank_increase_form_fill.scss";
@import "./components/step.scss";
@import "./components/gi_apply_form.scss";
@import "./components/form_definition.scss";
@import "./components/custom_menu_item.scss";
@import "./components/entity-permission.scss";
@import './_layout.scss';
@import './components/sider.scss';
@import './components/notice.scss';
@import './components/event-calendar.scss';
@import './components/birthdays.scss';
@import './components/search-result.scss';
@import './components/sider-intranet.scss';
@import './components/content-container.scss';
@import './components/home.scss';
@import './components/home-slider.scss';
@import './components/intranet-content-card.scss';
@import './components/dayoff_request.scss';
@import './components/myTasks.scss';
@import './components/myRequests.scss';
@import './components/corporate-demand.scss';
@import './components/corporate-asset.scss';
@import './components/corporate-templates.scss';
@import './components/corporate-images.scss';
@import './components/corporate-videos.scss';
@import './components/thank-you-widget.scss';
@import './components/content-sub-categories.scss';
@import './components/content-detail.scss';
@import './components/vote-content.scss';
@import './components/breaking-news.scss';
@import './components/birthday-greeting.scss';
@import './components/meal-menu-management.scss';
@import './components/file-management.scss';
@import './components/information-popup.scss';
@import './components/view-qr-code.scss';
@import './components/imza.scss';
@import'../../../node_modules/yet-another-react-lightbox/dist/styles.css';
@import './components/news-from-us.scss';
@import './components/ideas.scss';
@import './components/survey.scss';
@import './components/admin.scss';
@import './components/corporate-items.scss';
@import './components/travel_request.scss';


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: "bold";
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

html{
  font-size: 13px;
  --primary-color: var(--primary-color-light); /* Dark tema değilse primary rengini kullan */
  --secondary-color: var(--secondary-color-light); /* Dark tema değilse primary rengini kullan */
  --primary-impact-color: var(--primary-impact-color-light); /* Dark tema değilse primary rengini kullan */
  --primary-demand-color: var(var(--primary-demand-color-light));
  --primary-divider-color : var(--primary-divider-color-light);
  background-color: var(--primary-background-color-light);
}

.newsFromUsHeader
{
  .ant-tree-list-holder-inner, .ant-tree-switcher{
    background-color: var(--primary-demand-color);
    color: var(--secondary-color);
  }
  .ant-tree-node-content-wrapper:hover{
    background-color: lightgray;
  }
  .ant-tree-node-selected{
    background-color: var(--primary-background-color);
  }
}

.thankYouButton{
  color: var(--primary-impact-color); 
  font-size: 11px; 
  border: 1px solid var(--primary-impact-color); 
  background: none; 
  height: 21px; 
  padding: 0; 
  width: 90%;
  border-radius: 8px;
}
.thankYouButton:hover{
  color: var(--primary-impact-color); 
  border: 1px solid var(--primary-impact-color); 
}

.thankYouIcon{
  padding-top: 5px;
  width: 48px !important;
}

.thankYouHeader{
  height: 40px !important;
  padding-top: 10px !important;
  margin-top: 10px !important;
  @media only screen and (max-width: 991px){
    margin-top: 0 !important;    
  }
}

.thanksBody{
  height:auto !important;
  padding-left:0px;
  overflow:unset;
  @media only screen and (max-width: 991px){
    height: 210px !important;
  }
}

.thankYouRow{
  gap:5px; 
  width:100%; 
  display:flex;
  justify-content:flex-start;
  flex-direction:column;
  align-content:flex-start;
  border-bottom: 1px solid var(--primary-divider-color);
  padding: 2px;
  padding-bottom: 4px;
  .ant-space:first-of-type{
    gap:4px !important;
  }
}

.thankYouRow:first-of-type{
  padding-top: 5px;
}
.thankYouRow:nth-of-type(3){
  border-bottom: none !important;
}

.thankYouSpan {
  padding-top: 2px;
  font-weight: 500;
  color: black;
}

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light,
.my-tasks-dropdown,
.user-profile-dropdown
{
  background-color: var(--hidden-popup-background-color);
  border: 1px solid white;
  border-radius: 5px;
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
  background-color: var(--hidden-popup-background-color);
  border: 1px solid white;
  border-radius: 5px;
  .ant-menu-title-content a{
    color : var(--hidden-popup-color);
  }
}

li.ant-dropdown-menu-item,
.ant-list-item-meta-title,
.user-profile-dropdown .item-list .item .text a,
.user-profile-dropdown .item-list .item .text,
.user-profile-dropdown .profile-content h3,
.user-profile-dropdown .profile-content .action span
{
  color : var(--hidden-popup-color);
}
li.ant-dropdown-menu-item-active {
  color : black !important;
}


.ant-list-item-meta-description {
  color : var(--hidden-popup-secondary-color);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.ideaRate{
  font-weight:"bold";
  color: var(--primary-color);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;
  background: #ffffff;
}

// .intranet-content-card-header{
//   border-bottom: 1px solid var(--primary-color);
// }

.corporate-demand-tabs{
  color: var(--primary-color);
}

.btRequestType{
  display: flex;
  justify-content: space-evenly;
}

.travelType{
  display: flex;
  justify-content: space-evenly;
}

.demandColLeft{
  padding-right: 10px !important;
  @media only screen and (max-width: 767px){
    padding-right: 0 !important;
  }
  .ant-form-item-label{
    label{
      @media only screen and (max-width: 400px){
        font-size: 12px !important;
      }
    }
  }
  .ant-form-item-control-input-content{
    @media only screen and (max-width: 400px){
      padding-right: 0;
    }
  }
  .ant-form-item{
    @media only screen and (max-width: 400px){
      margin-bottom: 10px;
    }
  }
  .costItemClass{
    @media only screen and (max-width: 400px){
      display: flex;
      align-items: center;
      font-size: 12px !important;
    }
  }
  .flex-between{
    @media only screen and (max-width: 400px){
      justify-content: space-around !important;
    }
  }
  .expenseDeleteCol{
    @media only screen and (max-width: 1100px){
      padding-bottom: 20px;
    }
  }
}
.demandColRight{
  padding-left: 10px !important;
  @media only screen and (max-width: 767px){
    padding-left: 0 !important;
  }  
  .dayoffRequestRow{
    span{
      @media only screen and (max-width: 400px){
        font-size: 12px;
      }
    }
  }
  .costSpanTitle,.costSpanDesc{    
    @media only screen and (max-width: 400px){
      font-size: 12px;
    }
  }
  .ant-divider-horizontal{
    @media only screen and (max-width: 400px){
      margin: 12px 0 !important;
    } 
  }
}

.ant-dropdown-menu-item-selected{
  background-color: var(--primary-demand-color) !important;
}

.ant-table-filter-trigger.active{
  scale: 1.25;
}

.darkTheme{
  --primary-color: var(--primary-color-dark); /* Dark tema durumunda primary rengini kullan */
  --secondary-color: var(--secondary-color-dark); /* Dark tema değilse primary rengini kullan */
  --primary-background-color: var(--primary-background-color-dark); /* Dark tema değilse primary rengini kullan */
  --primary-impact-color: var(--primary-impact-color-dark); /* Dark tema değilse primary rengini kullan */
  --primary-demand-color: var(--primary-demand-color-dark);
  --primary-divider-color : var(--primary-divider-color-dark);

  .corporateButtons{
    background-color: #333f4c;
  }

  .ant-dropdown-trigger.ant-table-filter-trigger{
    color: var(--primary-demand-color-dark);
  }
  .ant-table-filter-trigger.active{
    color: #36679b;
  }

  
  .thankYouRow{
    color: var(--primary-color) !important;
    .thankYouSpan{
      color: var(--primary-color) !important;
    }
  }
  .newsFromUsBody{
      color: var(--primary-color);
      h2{
        color: var(--primary-color);
      }
  }

  .newsFromUsHeader
  {
    .ant-tree-node-content-wrapper:hover{
      background-color: var(--primary-hover-color-dark);
  
    }
  }

  .search-result-container{
    .ant-tabs-tab div{
      color : var(--secondary-color) !important;
    }
    .ant-tabs-tab-active div{
      color : var(--primary-color) !important;

    }
  }

  .search-result-list{
    h2{
      color: var(--primary-color);
    }
    p{
      color: var(--secondary-color);
    }
    .item{
      background-color: var(--primary-background-color) !important;
    }
  }

  .search-result-person-list .item{
    background-color: var(--primary-demand-color-dark) !important;  
    .detail{
      color:var(--primary-color) !important;
    }
    strong{
      color:var(--secondary-color) !important;
    }
    a{
      color:var(--primary-impact-color) !important;
    }
  }

  .ant-table-cell:hover{
    background-color:var(--primary-hover-color-dark) !important;
  }
  .ant-table-cell:hover + .ant-table-row{
    background-color:var(--primary-hover-color-dark) !important;
  
  }

  background: var(--primary-background-color-dark) !important;
  header .header-top .menu-btn .icon {
    color:#ffffff !important;
  }
   .sidebar-menu .menu-items,
   .header-top,
   .header-bottom,
   .header-bottom .ant-menu,
   .intranet-content-card .ant-tabs-nav-list,
   .search-area input,
   footer,
   .search-results,
   .test ul.ant-dropdown-menu
   {
      background-color: var(--primary-background-color-dark) !important;
   } 


   .anticon-bell,
   .welcome-screen,
   .intranet-content-card-header h2,
   .intranet-content-card .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn,
   .intranet-content-card .intranet-content-card-body .ant-list-item .ant-list-item-meta .ant-list-item-meta-content .ant-list-item-meta-title,
   #footerNews,
   .search-area input,
   .rts___tab.rts___btn,
   .view.ql-editor.content,
   .ant-badge span,
   .ant-form-item-label label,
   .dayoffRequestRow ,
   .costItemClass,
   .ant-tabs-tabpane-active
   {
    color: var(--primary-color) !important;
   }

   .costCard{
    span{
      color: var(--primary-color) !important;
    }
   }

   .ant-list-item-meta-description,
   .copyright span,
   .sidebar-menu .menu .menu-items a,
   .short-searchs ul li,
   .ant-list-empty-text,
   .rts___tab.rts___btn.rts___tab___selected,
   .vote-content p,
   .vote-content p span,
   .dayoffRequestRow div:last-child
   {
    color : var(--secondary-color) !important;
   }

   .ant-picker-input input::placeholder,
   .ant-picker-input span,
   .ant-select-selection-search input::placeholder,
   .ant-select-selection-placeholder,
   .corporate-demands-btn span
   {
    color: #25748bba !important;
   }

   .ant-divider
   {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
   }
   .ant-switch{
    background-color : #456;
   }

   .intranet-content-card {
     box-shadow: 2px 2px 5px 1px rgba(149, 185, 224, 0.46);
   }
   
   .sidebar-menu .menu .menu-items a .menu-item-icon{
     background-color: #39b3f552 !important;
   }

   .themeSelector {
    border:2px solid white;
    border-radius: 50px;
   }

   
   .contentPageDescription{
    p, ul, li, div {
      color: var(--primary-color) !important;
    }
    h2{
      color: var(--secondary-color) !important;
    }
   }

   .corporate-asset,.corporate-demand{
    background-color: var(--primary-demand-color-dark ) !important;
    .ant-card-body{
      background-color: var(--primary-demand-color-dark) !important;
      .ant-card-meta-title, .ant-card-meta-description{
        color: var(--primary-color-dark);
      }
    }
    }

    .corporate-demand{
      .ant-table{
        background-color: var(--primary-demand-color-dark)  !important;
        color: var(--primary-color);
        tr{
          background-color: var(--primary-demand-color-dark)  !important;
        }
      }
      .ant-table-cell-row-hover{
        background-color: var(--primary-hover-color-dark)  !important;
      }
      thead{
        .ant-table-cell{
          background-color: var(--secondary-demand-color-dark)!important;
          font-weight: bold !important;
        }

      }
    }

    .ant-card-head
    {
      background-color: var(--secondary-demand-color-dark)!important;
    }


   .corporate-demand{
    background-color: rgb(51, 63, 76) !important;
    .ant-card{
      background-color: var(--primary-demand-color-dark) !important;
    }
    .ant-card-body{
      background-color: var(--primary-demand-color-dark) !important;
      .ant-card-meta-title, .ant-card-meta-description{
        color: var(--primary-color-dark);
      }
    }
    }
  .result-group ul{
    .title, .user{
      color: var(--primary-color) !important;
    }
    .description{
      color: var(--secondary-color);
    }
  }



  .intranet-content-card-header .title h2 {
    color: #fff !important;
  }

   div::-webkit-scrollbar {  width: 3px;  height: 10px;  background-color: #f5f5f5;}
   div::-webkit-scrollbar-thumb {  background-color: #90b8e6}
   div::-webkit-scrollbar-thumb:hover {  background-color: #a0a0a0;}
   div::-webkit-scrollbar-track {  background-color: var(--primary-background-color-dark)}

}

.filter {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.colored-image {
  filter: url(#replace-white-with-rgb);
}

.headerComp {
  padding-top:90px;
  padding-left:30px; 
  min-height:calc(100vh - 114px)
}

button {
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
}


.height-default{max-height: inherit !important;}
.ant-anchor{
  .custom-menu {max-height: calc(100vh - 460px);overflow: auto;}
}
.ant-modal-body{
  .content-card-basic {
    .header{flex-direction: column; gap: 15px;}
  }
  .ant-anchor-ink{display: none;}
}
.rts___tabs{overflow-x:auto; overflow-y: hidden;}


#footerNews { 
  font-weight : bold !important;
  text-wrap : nowrap !important;
  padding-right: 5px !important;
}

.profile-demands{
  margin-top:20px ;
}

body > iframe[style*='2147483647']{
  display: none;
}


.ideaBtn{
  border: 1px solid var(--primary-impact-color) !important;
  span:first-of-type{
    color: var(--primary-impact-color)
  }
  span{
    color:var(--primary-impact-color)
  }
}

.ant-list-split .ant-list-item{
  border-bottom : 1px solid var(--primary-divider-color);
}


.ant-table-filter-dropdown{
  min-width: 200px !important;
}

.fa{
  &.centralOffice{ width: 15px; color: var(--primary-impact-color); padding-left: 4px !important}
  &.normalOffice{ width: 15px;  color: var(--primary-impact-color); padding-left: 4px !important}
  &.homeOffice{ width: 15px; color: var(--primary-impact-color); padding-left: 2px;}
  &.selectedOffice{ padding-left:2px;  scale: 1.5; color: var(--primary-color);}
}

.officeTab{
  justify-content: space-between;
}
.officeTabRightPane{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.office-list{
  display: grid;
  grid-template-columns: repeat(1,1fr); 
  gap: 5px; 
  background: var(--hidden-popup-background-color); 
  padding: 5px; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.19);
  width:250px;  
  border: 1px solid var(--primary-divider-color);
  border-radius: 5px;
  .item{ 
    font-size: 15px;
    cursor: pointer;
  }
  .item:last-of-type{ 
    border-bottom: none ;
  }
  .office-list-header{
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid var(--primary-demand-color-light);
    h2{
      font-size: 16px;
      color:  #ca5151;
      font-weight: bold;
    }
  }
  .officeSelection {
    background: var(--hidden-popup-background-color); 
    color: var(--hidden-popup-color); 
    display:flex;
    justify-content:space-between;
    align-items:center;
    cursor: pointer;    
    border: 1px solid var(--primary-divider-color);
    border-radius: 5px;
    padding: 5px;
    padding-left: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
    .officeImage{
      display: flex;
      align-items:center;
      border: 1px solid var(--primary-color);
      border-radius: 5px;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 5px 3px rgba(0, 0, 0, 0.19);
      img{
        border-radius: 5px;
      }
    }
  }
  .officeSelection:hover {
    color : black;
    background: linear-gradient(-45deg, #c6d6f3 0%,  #fff 100%);
  }
  .selectedOffice,.selectedOffice:hover{
    color : black;
    background: linear-gradient(-45deg, #8ca5d3 0%,  #fff 100%);
  }
  .fa{
    scale: 1.3;
  }
}

.officePreviewImg{
  .ant-image-mask{
    color:transparent !important;
    background : transparent !important
  }
  border: 1px solid var(--primary-color);
      border-radius: 5px;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 5px 3px rgba(0, 0, 0, 0.19);
      img{
        border-radius: 5px;
      }
}

.officeSelector{
  display: flex;
  align-items: center;
  color: black;
  margin-right: -15px;
  @media screen and (max-width: 700px) {
    margin-left: 10px;
    margin-right: 0;
  }
  .officeSelectorIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    font-size:28px;
    margin-left: 2px;
    color: var(--primary-color)
    // border: 2px solid black;
    // border-radius: 3px;
  }
}

.welcome-screen{
  margin-right: -15px;
}

.corporate-demand{
  .ant-table{
    background-color: var(--primary-background-color-light)  !important;
    color: var(--primary-color);
    tr{
      background-color: var(--primary-background-color-light)  !important;
    }
  }
}

.boardIdeaFilter{
  margin-bottom: 10px;
  border-radius: 20px;
  .ant-card-body{
    padding: 5px;
  }
  .ant-collapse-header-text{
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .ant-select,.ant-input-search{
    padding-bottom: 10px;
  }
  .ant-select-clear{
    top:32%;
  }
}

.boardIdeaCard{
  margin-bottom: 20px;
  border-radius: 20px;
  .boardIdeaCardIdeaTypes{
    display: flex;
    flex-direction: column;
    justify-content: left;
    span{
      padding: 3px 0 0 0;
      display: flex;
      align-items: flex-end;      
      img{
        margin-right: 5px;
      }
    }
  }
  .ant-col:last-of-type{
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
}


@keyframes menuItemsAnim {
  50% {display: block;}
}

.sliderFooter{
  padding-top: 20px;
  padding-bottom: 25px;
  color : white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .sliderFooterHeader{
    font-weight: bold;
  }
  .sliderFooterBody{
    padding-top: 5px;
  }
}

.yarl__slide.yarl__flex_center{
  flex-direction: column !important;
}

.activitiesTabLeft{
  height: 200px !important;
}