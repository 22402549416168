// .detail-modal{
//     .ant-modal-content{
//         .ant-modal-body{
//             .ant-collapse{
//                 .ant-collapse-item{
//                     .ant-collapse-header{
//                         .ant-collapse-header-text{
//                             font-size: 13px;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

.detail-modal{   
    .ant-collapse{
        .ant-collapse-item{            
            .ant-collapse-header{
                padding-left: 0 !important;
                display: flex;
                align-items: center;
            }
        }
    }
    .ant-collapse-expand-icon{
        color: $primary-color !important;
    }
    .ant-collapse-header-text{
            font-size: 13px !important;
            color: $primary-color !important;
    }
    .ant-collapse-content-box{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
} 