.cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 21px 0;
  position: relative;

  @include MQ(SM){
    margin: 10px 0;
  }

  &.boldBody{
    .cell-body{
      font-weight: bold!important;
    }
  }
  
  
  &.boldTitle{
    .cell-title{
      color: #23374d!important;
      font-weight: bold!important;
    }
  }
  
  &.onlyMarginBottom{
    margin: 0;
    margin-bottom: 30px;
  }

  &.large {
    .cell-title {
      font-size: 14px!important;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #a2aebc;
      margin-bottom: 11px;
    }
  }

  &.has-sep {
    padding-bottom: 16px;

    &::after {
      content: " ";
      position: absolute;
      bottom: -0px;
      border-radius: 8px;
      border: solid 1px #eceef4;
      height: 1px;
      width: 100%;
    }

    &:last-child{
        &::after{
            display: none;
        }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .cell-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;

    .cell-icon {
      width: 20px;
      i {
        font-size: 16px;
        color: $primary-color-2;
      }
    }
    .cell-title {
      flex: 9;
      margin-left: 5px;
      font-size: 12px;
      color: #96a8b7;
    }
  }
  .body-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    .body-spacer {
      width: 20px;
    }
    .cell-body {
      flex: 9;
      font-size: 14px;
      color: #23374d;
      margin-top: 3px;
      margin-left: 5px;
      line-height: 1.21;
      word-break: break-word;
    }
  }
}
