$dash-blue: #40a3e9;
$dash-orange: #f6aa60;
$dash-green: #ff3b46;
$dash-red: #f03f47;

.colored-dash-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 120px;
  // width: 250px;
  color: #fff;
  padding: 13px 18px;
  border-radius: 20px;
  box-shadow: 0 6px 10px 0 rgba(35, 55, 77, 0.1);

  &.blue {
    background-color: $dash-blue;
    .sub-number-item {
      color: $dash-blue;
    }
  }
  &.orange {
    background-color: $dash-orange;
    .sub-number-item {
      color: $dash-orange;
    }
  }
  &.green {
    background-color: $dash-green;
    .sub-number-item {
      color: $dash-green;
    }
  }
 
  &.red {
    background-color: $dash-red;
    .sub-number-item {
      color: $dash-red;
    }
  }

  > .total-number {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .number {
      font-size: 39px;
      font-weight: 900;
      text-align: center;
      line-height: 1.1;
      margin-bottom: 5px;
    }

    > .label {
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center;
    }
  }

  > .sub-numbers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;

    > .sub-number-item {
      width: 120px;
      height: 40px;
      background-color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      line-height: 1.4;
      padding: 12px 7px;
      border-radius: 25px;
      margin-bottom: 10px;
      text-align: center;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
