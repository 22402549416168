$primary-color: var(--primary-icon-color);
$secondary-color: var(--primary-icon-color);

.search-result-container {display: flex;flex: 1; flex-direction: column;

    .anticon-search{
        display: none !important;
    }
    .ant-input-search{
        .ant-input-group{position: relative; display: block;
            .ant-input-group-addon{position: absolute; right: 10px; left: auto; background: none; width: 30px;z-index: 9;
                button{background: none;color: rgba(0, 0, 0, 0.45) !important;
                    &:hover{color: rgba(0, 0, 0, 0.45) !important;}
                }
            }
        }
    }
    .search-result-list{
        .item {width: 100%;display: flex; flex-direction: column; padding: 5px; transition: all .2s; border-bottom: 1px solid #e9e9ed; margin-bottom: 10px; padding-bottom: 10px;
            .title{ display: flex; align-items: center; gap: 7px;
                h2 {font-size: 13px; font-weight: 500; margin: 0; padding: 0;}
                img {height: 20px;width: 20px;margin-right: 10px;margin-top: 0;}

            }
            p {font-size: 13px;margin: 0;padding-bottom: 5px}
            a {font-size: 12px;margin: 0;color: var(--primary-impact-color)}
            &:hover{background: #f2faff;}
        }
    }
    .search-result-person-list{ display: flex; flex-direction: column; gap: 20px;
        .item{ padding: 15px; background-color: #fafafa;
            @media only screen and (max-width: 992px) {&{display: flex;}}
            @media only screen and (max-width: 790px) {&{ flex-direction: column;}}

            .top{ display: flex; align-items: center; border-bottom: 1px solid #c9c9c9; padding-bottom: 15px;
                @media only screen and (max-width: 992px) {&{width: 100%; border:none; padding: 0;}}
                @media only screen and (max-width: 540px) {&{ flex-direction: column; align-items:flex-start !important}}

                .avatar{ width: 100px; display: flex; justify-content: center !important; align-items: flex-start !important;
                    img{width: 80px; height: 80px; object-fit: cover; border-radius: 100%; border: 2px solid var(--primary-impact-color); padding: 2px;}
                }
                .content{  display: flex;  width: calc(100% - 100px);
                    @media only screen and (max-width: 992px) {&{flex-direction: column; padding: 0;}}
                    @media only screen and (max-width: 540px) {&{ width: 100%;}}
                }
            }
            .bottom{  display: flex; align-items: center; margin-top: 20px; width: calc(100% - 100px);
                @media only screen and (max-width: 992px) {&{flex-direction: column; align-items: flex-start; margin: 0; border-left: 1px solid #c9c9c9;}}
                @media only screen and (max-width: 790px) {&{ margin-left: 100px;width: 100%; padding-top: 10px}}
                @media only screen and (max-width: 540px) {&{margin:0; border:none;width: 100%;}}
                .row-item{border:none;}
            }
            .row-item{border-left: 1px solid #c9c9c9; justify-content: center; display: flex; flex-direction: column; flex: 2; padding-left: 20px; gap: 7px;
                @media only screen and (max-width: 992px) {&{gap: 0; flex:0; padding-right: 20px;}}
                @media only screen and (max-width: 540px) {&{border: none;}}
                @media only screen and (max-width: 790px) {&{ padding-top: 10px;}}
                &.first{flex: 1;}
                .detail{ font-size: 13px; color: #4f5769; line-height: 20px;
                    &.big{font-size: 14px;}
                    strong{color: #888888; font-weight: normal;}
                }
            }
            a{color: #5371aa;}
        }
    }
    
    .ant-tabs {overflow: inherit !important;flex: 1;}
    .ant-tabs-tabpane {overflow: auto;}
    .ant-tabs-nav {
        &:before{bottom: 0px;}
    }
    .ant-tabs-nav-list {flex: 1;justify-content:flex-start;
        .ant-tabs-tab{ background: none !important; padding: 0 !important; margin: 0 !important;
            .ant-tabs-tab-btn {font-size: 15px;font-weight: normal;padding: 12px 15px; color: #666665; border-bottom: 2px solid transparent;}
            &.ant-tabs-tab-active{
                .ant-tabs-tab-btn{border-color: var(--primary-icon-color); color: #000;}
            }
        }
    }
    .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {display: flex;align-items: center;justify-content: space-between;background-color: #fff;border: 0;color: #666665;
        &:hover,&.active {}
    }
}
