.newsFromUsHeader
{
    height: auto !important;
    .title{
        padding-top: 30px;
        padding-bottom: 30px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media only screen and (max-width: 900px){
            flex-direction: column-reverse;
        }
        .smallTitle{
            justify-content: left;
            @media only screen and (max-width: 900px){
                flex-direction: row !important;
            }
        }
        .newsTree{
            width: 400px;
            @media only screen and (max-width: 900px){
                width: 100% !important;
            }
        }
        .newsImage{
            height: 400px !important;
            width: 100% !important;
            @media only screen and (max-width: 1200px){
                width: 600px !important;
                height: auto !important;
            }
            @media only screen and (max-width: 1000px){
                width: 500px !important;
                height: auto !important;
            }
            @media only screen and (max-width: 900px){
                width: 100% !important;
                height: auto !important;
            }
        }
        .treeBody{
            width: 100%;
            // overflow: 'auto'
            height: 350px !important;
            @media only screen and (max-width: 1200px){
                height: 300px !important;
            }
            @media only screen and (max-width: 1000px){
                height: 250px !important;
            }
            @media only screen and (max-width: 900px){
                height: 300px !important;
            }
        }
    }
}

.newsFromUsBody
{
    padding-bottom: 20px;
}