$header-height: 80px;
.ant-layout-header {
  height: $header-height;
  color: inherit;

  .header-inner {
    height: $header-height;
    display: flex;
    flex-direction: row;
    padding: 0px 40px;
    line-height: 26px;
    justify-content: flex-end;

    @include MQ(SM){
      padding: 0;
      justify-content: space-between;
    }

    .ant-input-affix-wrapper {
      flex: 1;
      padding: 0;
    }

    .header-menu {
      li {
        height: 100%;
        // min-width: 150px;
        padding: 0 20px;
        border-left: $sep-color solid 1px;

        @include MQ(SM){
          padding: 6px;
        }
      }

      .header-menu-item {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        cursor: pointer;

        &:hover {
          color: $primary-color-2;
          .icon {
            color: $primary-color-2;
          }
        }

        .notification-icon {
          font-size: 26px;
        }
        .icon {
          font-size: 14px;
          color: $title-color;
          font-weight: bold;
        }

        .text {
          font-size: 13px;
        }
      }
    }
  }
}


