.content-card{
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    margin: 0 auto 20px auto;
    padding: 25px 30px;
    background-color: white;
    border-radius: 20px;
    max-width: 880px;
    position: relative;
    transition: all .2s;
    overflow: hidden;

    .collapse-toggle{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: none;
        outline: none;
        border: none;
        color: $primary-color-2;
        font-size: 21px;
        cursor: pointer;
        z-index: 1;
        width: 100%;
        text-align: left;
        padding: 0;
    }
}


.content-card-basic{
    position: relative;
    background-color: white;
    padding: 20px 25px;
    width: 100%;
    //margin: 0;
    border-radius: 15px;


    &.with-shadow{
        box-shadow: 1px 1px 4px rgba($color: #000000, $alpha: .1);
    }

    &.red-shadow{
        box-shadow: 1px 1px 4px rgba($color: $accent-color, $alpha: .4);
    }
    
    &.green-shadow{
        box-shadow: 1px 1px 4px rgba($color: $primary-color-v2, $alpha: .4);
    }
    &.no-padding {
        padding: 0;
    }


    .header, .body{
        padding: 20px 25px;
    }

    .header{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        position: relative;
        padding-bottom: 23px;
        border-bottom: solid 0.5px #eceef4;
        align-items: center;

        @include MQ(SM){
            flex-direction: column;
        }

        // &:after{
        //     content: " ";
        //     width: 100%;
        //     height: 0.5px;
        //     background-color: #eceef4;
        //     position: absolute;
        //     left: 0;
        //     right: 0;
        // }
    }


    .body{
        display: flex;
        flex-direction: column;
    
        &.wrap{
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
        }

       
    }


    .footer{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 10px;
        
        // position: absolute;
        // bottom: 0;
        border-top: solid 1px #eceef4;
    }
}