.reset__rts__btn{border:none; background: none; border-radius: 0; box-shadow: none;  margin:0}

.content-sub-categories{
    .display___md___none{display: flex !important;}
    .content-sub-tabs{
        .rts___nav___btn___container{border-bottom: 1px solid var(--primary-divider-color); 
            .rts___nav___btn{ @extend .reset__rts__btn;}
            &:nth-child(1){padding: 0 10px 0 0;}
            &:nth-last-child(1){padding: 0 0 0 10px;}
        }
        .rts___tabs{border-bottom: 1px solid var(--primary-divider-color);
            .rts___btn{ font-size: 15px; color:#666665; display: flex; justify-content: center; align-items: center; gap: 5px; padding: 12px 20px; @extend .reset__rts__btn; position: relative;
                &:after{content: ""; width: 0%; height: 2px; display: block; position: absolute;left: 0; bottom: -11px; background: var(--primary-impact-color); transition: width .4s;}
                &.rts___tab___selected, &:hover{
                    &::after{width: 100%;}
                }
                &.rts___tab___selected{color: #000; font-weight: 500;
                    i{color: var(--primary-impact-color);}
                }
            }
        }
        &.level-2{ margin: 0 20px;
            .rts___nav___btn___container{border-bottom:none }
            .rts___tabs{border-bottom: none;
                .rts___btn{border: 1px solid transparent; transition: all .4s;border-radius: 25px;
                    &:after{display: none;}
                    &.rts___tab___selected{border-color:  var(--primary-impact-color); color: var(--primary-impact-color);}
                    &:hover{ color: var(--primary-impact-color);}
                }
            }
        }
    }
}