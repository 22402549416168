.education-category-container {
  // display: flex;
  // flex-direction: row;
  .info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
    .title {
      width: 235px;
      color: $title-color;
      font-size: 50px;
      font-weight: bold;
      line-height: 1.25;
    }

    .description {
      margin-top: 16px;
      font-size: 16px;
      line-height: 1.5;
    }

    .navigation {
      margin-top: 30px;

      .buttons {
        display: flex;
        justify-content: space-between;

        .arrows {
            display: flex;
            justify-content: space-around;
          button {
              outline: none;
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 40px;
            border: solid 1px $body-text-color;
            &:hover{
                background-color: $primary-color-2;
                color: white;
            }
          }
        }
      }
    }
  }

  .slick-list {
    padding-top: 22px;
    padding-left: 22px;
  }
}
