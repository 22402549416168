.mentorship-process-list-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  margin-bottom: 62px;
  // cursor: pointer;
  transition: all 0.2s;

 

  .rank,
  .recommend {
    margin: 20px 10px;
    button {
      width: 100%;
    }
  }

  > div {
    flex: 40%;
    // margin: 0 10px;
  }
  // max-width: 365px;

  .status {
    min-width: 200px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    text-align: center;
    color: white;

    &.inprogress, &.notStarted, &.waiting, &.canceledByTimeout, &.waitingForComments {
      background-color: #e8c241;
    }
    

    &.completed {
      background-color: #96d18e;
    }
    
    &.declined {
      background-color: #ff3b46;
    }
    
   
  }

  .pointer {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 27px;
    height: 30px;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    text-align: left;
    color: $primary-color-2;
  }

  &:hover {
    transform: scale(1.02);
  }

  &:last-child {
    margin-bottom: 0;
    &::after {
      display: none;
    }
  }

  &::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -31px;
    border-radius: 20px;
    background-color: #c0d7ea;
    // border: solid 1px #c0d7ea;
  }

  &.selected {
    padding-left: 15px;
    transform: scale(1.02);
    .pointer {
      display: block;
    }
    &::before {
      content: " ";
      position: absolute;
      width: 5px;
      height: 100%;
      border-radius: 20px;
      background-color: #10a3e9;
      left: 0px;
    }

    .id {
      color: $primary-color-2;
    }
  }

  .id {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #23374d;
  }
}
